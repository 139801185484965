import { ReactNode, useEffect, useState } from "react";
import api from "../../services/api";
import Loading from "../Loading";
import { useHistory } from "react-router-dom";

interface ICanProps {
  children: ReactNode;
  roles?: string;
  permissions?: string[];
}
export default function Can({ roles, children }: ICanProps) {
  const [permissions, setPermissions] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setLoading(false);
    async function loadRoles() {
      const response = await api.get("/roles/user");
      
      const findRole = await response.data.some((r: string) =>
        roles?.split(",").includes(r)
      );
      setPermissions(findRole);
      setLoading(true);
    }

    loadRoles();
  }, [roles]);

  if (!permissions && loading) {
    history.push("/overview");
  }

  return permissions && loading ? (
    <>{children}</>
  ) : (
    <Loading width={10} height={10} color="primary" />
  );
}
