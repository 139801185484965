import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

interface MenuContextState {
  isOpen: boolean;
  isSticky: boolean;
  toggle: boolean;
  notificaWeb: boolean;
  toggleSidebar(): void;
  handleToggle(): void;
  handleNotificaWeb(web: boolean): void;
}

interface MenuProviderProps {
  children: ReactNode;
}

const MenuContext = createContext<MenuContextState>({} as MenuContextState);

const MenuProvider = ({ children }: MenuProviderProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(true);

  const isToggle =
    localStorage.getItem("@supply:toggle") === "false" ? false : true;
  const [toggle, setToggle] = useState(isToggle);
  const isNotificaWeb =
    localStorage.getItem("@supply:isNotificaWeb") === "false" ? false : true;
  const [notificaWeb, setNotificaWeb] = useState(isNotificaWeb);

  const handleToggle = (): void => {
    setToggle(!toggle);
  };

  const handleNotificaWeb = (): void => {
    setNotificaWeb(notificaWeb);
  };

  useEffect(() => {
    localStorage.setItem("@supply:toggle", toggle.toString());
  }, [toggle]);

  const toggleSidebar = useCallback(() => {
    setIsOpen(!isOpen);
    setIsSticky(!isSticky);
  }, [isOpen, isSticky]);

  return (
    <MenuContext.Provider
      value={{
        isOpen,
        isSticky,
        toggle,
        notificaWeb,
        toggleSidebar,
        handleToggle,
        handleNotificaWeb,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

function useMenu(): MenuContextState {
  const context = useContext(MenuContext);
  return context;
}

export { MenuProvider, useMenu };
