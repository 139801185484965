import React, { useState } from "react";
import { Button, Card, CardBody, Alert } from "reactstrap";
import { Form } from "@unform/web";
import supplyLogo from "../../assets/supply/logo_mini_supply.png";
import smsImg from "../../assets/sms-logo.png";
import whatsappImg from "../../assets/whatsapp-logo.png";
import api from "../../services/api";
import { useCallback } from "react";
import { Link, useHistory } from "react-router-dom";

export default function AccessCode() {
  const history = useHistory();
  const [erro, setErro] = useState("");

  const tel  = localStorage.getItem("@supply:cell");
  const handleSubmit = useCallback(() => {}, []);

  async function handleWhatsapp() {
    try {
      const response = await api.post("/envioToken/whatsapp", { cell: tel });
      if (response.status === 200) {
        history.push("/login");
      }
    } catch (error) {
      setErro(
        "Prezado usuário(a) não foi possível realizar o envio do seu código de acesso verifique se o número" +
          "digitado foi o mesmo cadastrado caso o erro persiste entra em contato com a nossa central de atendimento"
      );
    }
  }

  async function handleSMS() {
    try {
      const response = await api.post("/envioToken/sms", { cell: tel });
      if (response.status === 200) {
        history.push("/login");
      }
    } catch (error) {
      setErro(
        "Prezado usuário(a) não foi possível realizar o envio do seu código de acesso verifique se o número" +
          "digitado foi o mesmo cadastrado caso o erro persiste entra em contato com a nossa central de atendimento"
      );
    }
  }

  return (
    <>
      <div
        style={{
          margin: 0,
          display: "grid",
          flex: 1,
          height: "100%",
          justifyContent: "center",
          alignContent: "center",
          justifySelf: "center",
        }}
      >
        <Card style={{ maxWidth: "600px", maxHeight: "600px", marginTop: 20 }}>
          <CardBody>
            <div className="m-sm-4">
              <div className="text-center">
                <img
                  src={supplyLogo}
                  alt="Supply"
                  className="img-fluid "
                  width="132"
                  height="132"
                />
              </div>
              <div className="text-center mt-2">
                <br/>
                <p>
                  Seja bem vindo de volta{" "}
                  <b>{localStorage.getItem("@supply:name")}</b> <br />
                  Como deseja receber seu código de acesso ?
                </p>
                <br />
              </div>
              <Form onSubmit={handleSubmit}>
                <div className="text-center mt-3">
                  <Button
                    outline
                    color="success"
                    size="lg"
                    block
                    onClick={handleWhatsapp}
                    style={{ textAlign: "center" }}
                  >
                    <img
                      src={whatsappImg}
                      alt="Whatsapp"
                      className="img-fluid "
                      width="32"
                      height="32"
                    />
                    &emsp; Whatsapp
                  </Button>

                  <br />
                  <br />

                  <Button
                    outline
                    color="info"
                    size="lg"
                    block
                    onClick={handleSMS}
                    style={{ textAlign: "center" }}
                  >
                    <img
                      src={smsImg}
                      alt="SMS"
                      className="img-fluid "
                      width="32"
                      height="32"
                      style={{ marginLeft: 0 }}
                    />
                    &emsp;&emsp;&emsp; SMS
                  </Button>
                  <br />
                  <br />
                  <Link to="/haveAcessCode">Já tenho meu código!</Link>
                </div>
              </Form>
              <div>
                {!!erro && (
                  <Alert color="danger" style={{ textAlign: "center" }}>
                    {erro}
                  </Alert>
                )}
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
}
