import React, { useCallback, useEffect, useRef, useState } from "react";
import { Container, Row, Col, Button, Spinner } from "reactstrap";

import { Accordion } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";

import { AiOutlineWhatsApp, AiOutlineMessage } from "react-icons/ai";
import { AlertCircle, RefreshCw } from "react-feather";
import { faCheck, faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Temperatura } from "../../components/charts/Temperatura";
import { Umidade } from "../../components/charts/Umidade";
import { Fase } from "../../components/charts/Fase";
import { Analise } from "../../components/charts/Analise";

import DatePicker from "../../components/DataPicker";

import Loading from "../../components/Loading";

import "./styles.css";
import api from "../../services/api";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import { ModalJustificativaInterno } from "../../components/ModalJustificativaInterno";
import { ScrollTop } from "primereact/scrolltop";
import { AxiosError } from "axios";

interface IDataHistory {
  dataHistory: {
    nome_ambiente: string;
    descricao_medicao: string;
    periodoInicio: string;
    periodoFim: string;
    tipo: number;
    datas: string[];
    serie_A: number[];
    serie_B: number[];
    serie_C: number[];
    presetMin: number;
    presetMax: number;
  };
}

interface PropsDates {
  dtfim: string;
  dtini: string;
}

interface IAccordion {
  totalDeNotificacoes: number;
  totalDePaginas: number;
  qtdPorPagina: number;
  indicePagina: number;
  periodoInicio: string;
  periodoFim: string;
  historicoNotificacoes: [
    {
      id: number;
      date_Hour: string;
      notification: string;
      justificativa: string;
      justificavel: boolean;
      whatsappEnviadoEm: string;
      whatsappRecebidoEm: string;
      whatsappLidoEm: string;
      smsEnviadoEm: string;
    }
  ];
}

export default function DataHistory() {
  const [dataHistory, setDataHistory] = useState({} as IDataHistory);
  const [accordions, setAccordions] = useState({} as IAccordion);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [pag, setPag] = useState(1);
  const [dataInit, setDataInit] = useState("");
  const [dataFina, setDataFina] = useState("");
  const history = useHistory();
  const plataform = localStorage.getItem("@supply:plataform");
  const {
    eStation,
    typeMeasurement,
  }: { eStation: string; typeMeasurement: string } = useParams();

  useEffect(() => {
    if (eStation) {
      api.post(`/logs`, {
        url: `/dataHistory/${eStation}/${typeMeasurement}`,
      });
    } else {
      api.post(`/logs`, {
        url: `/dataHistory/${typeMeasurement}`,
      });
    }
  }, [eStation, typeMeasurement]);

  useEffect(() => {
    api
      .post<IDataHistory>(`/dataHistory/${typeMeasurement}`)
      .then((response) => {
        setDataHistory(response.data);

        setDataInit(response.data.dataHistory?.periodoInicio);
        setDataFina(response.data.dataHistory?.periodoFim);
      })
      .catch((error: AxiosError) => {
        history.push("/500");
        api.post("/logs/frontEnd", {
          log: JSON.stringify(error.stack),
          platform: plataform ? plataform : "web",
        });
      })
      .finally(() => setLoading1(true));
  }, [typeMeasurement, history, plataform]);

  useEffect(() => {
    api
      .post<IAccordion>(`/dataHistory/table/${typeMeasurement}/${pag}`)
      .then((response) => {
        setAccordions(response.data);
      })
      .catch((error: AxiosError) => {
        history.push("/500");
        api.post("/logs/frontEnd", {
          log: JSON.stringify(error.stack),
          platform: plataform ? plataform : "web",
        });
      })
      .finally(() => {
        setLoading2(true);
        setLoading3(true);
      });
  }, [typeMeasurement, history, pag, plataform]);

  function handleNextPag() {
    if (pag < accordions.totalDePaginas) {
      setLoading3(false);
      setPag(pag + 1);
    }
  }

  function hanslePrevious() {
    if (pag > 1) {
      setLoading3(false);
      setPag(pag - 1);
    }
  }

  const formRef = useRef<FormHandles>(null);
  const handleSubmit = useCallback(
    ({ dtfim, dtini }: PropsDates) => {
      setLoading1(false);
      setLoading2(false);
      setLoading3(false);
      setDataHistory({} as IDataHistory);

      api
        .post<IDataHistory>(`/dataHistory/${typeMeasurement}`, {
          dataInicial: dtini,
          dataFinal: dtfim,
        })
        .then((response) => {
          setDataHistory(response.data);

          setDataInit(response.data.dataHistory?.periodoInicio);
          setDataFina(response.data.dataHistory?.periodoFim);
        })
        .catch((error: AxiosError) => {
          history.push("/500");
          api.post("/logs/frontEnd", {
            log: JSON.stringify(error.stack),
            platform: plataform ? plataform : "web",
          });
        })
        .finally(() => setLoading1(true));

      api
        .post<IAccordion>(`/dataHistory/table/${typeMeasurement}/${pag}`, {
          dataInicial: dtini,
          dataFinal: dtfim,
        })
        .then((response) => {
          setAccordions(response.data);
        })
        .catch((error: AxiosError) => {
          history.push("/500");
          api.post("/logs/frontEnd", {
            log: JSON.stringify(error.stack),
            platform: plataform ? plataform : "web",
          });
        })
        .finally(() => {
          setLoading2(true);
          setLoading3(true);
        });
    },
    [typeMeasurement, history, pag, plataform]
  );
  const dataInicial = dataInit.split("/");
  const dataFinal = dataFina.split("/");

  const dateInitial = `${
    dataInicial[2] + "/" + dataInicial[1] + "/" + dataInicial[0]
  } `;

  const dateFinale = `${
    dataFinal[2] + "/" + dataFinal[1] + "/" + dataFinal[0]
  } `;

  return loading1 && loading2 ? (
    <>
      <Container fluid>
        <Row className="mb-2 mb-xl-4">
          <Col xs="auto" className=" d-sm-block">
            <h6
              style={{
                padding: 0,
                margin: 0,
              }}
            >
              HISTÓRICO DE MEDIÇÃO
            </h6>

            <p>{dataHistory.dataHistory?.descricao_medicao}</p>
          </Col>
          <Col xs="auto" className="ml-auto text-right ">
            <div
              style={{
                display: "flex",
                marginBottom: 0,
                paddingBottom: 0,
              }}
            >
              <Form ref={formRef} onSubmit={handleSubmit}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexWrap: "wrap",
                    marginTop: ".8rem",
                  }}
                >
                  <DatePicker
                    name="dtini"
                    label="De"
                    selectDate={new Date(dateInitial + " 00:00:00")}
                    required
                  />

                  <DatePicker
                    name="dtfim"
                    label="Até"
                    selectDate={new Date(dateFinale + " 00:00:00")}
                    required
                  />
                  <br />
                  <Button
                    type="submit"
                    style={{
                      width: "3rem",
                      height: "2rem",
                      backgroundColor: "#262F3D",
                      textAlign: "center",
                      justifyContent: "center",
                      justifyItems: "center",
                      alignContent: "center",
                      marginBottom: "15px",
                      padding: 0,
                    }}
                  >
                    <RefreshCw size={20} />
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
        {/* Gráfico de Umidade */}
        {dataHistory.dataHistory?.tipo === 1 ? (
          <Row>
            <Col lg="12" className="h-flex">
              <Umidade dtini={dateInitial} dtfim={dateFinale} />
            </Col>
          </Row>
        ) : (
          <></>
        )}

        {/* Gráfico de Temperatura */}
        {dataHistory.dataHistory?.tipo === 0 ||
        dataHistory.dataHistory?.tipo === 2 ||
        dataHistory.dataHistory?.tipo === 3 ? (
          <Row>
            <Col lg="12" className="h-flex">
              <Temperatura dtini={dateInitial} dtfim={dateFinale} />
            </Col>
          </Row>
        ) : (
          <></>
        )}
        {/* Gráfico de Fases */}
        {dataHistory.dataHistory?.tipo === 4 ? (
          <Row>
            <Col lg="12" className="h-flex">
              <Fase dtini={dateInitial} dtfim={dateFinale} />
            </Col>
          </Row>
        ) : (
          <></>
        )}

        {/* Gráfico de Fases */}
        {dataHistory.dataHistory?.tipo === 5 ? (
          <Row>
            <Col lg="12" className="h-flex">
              <Analise dataApi={dataHistory} />
            </Col>
          </Row>
        ) : (
          <></>
        )}

        <Row>
          <div style={{ display: "flex" }}>
            <h6
              style={{
                padding: 0,
                margin: 0,
              }}
            >
              HISTÓRICO DE NOTIFICAÇÕES
            </h6>
            {!loading3 && (
              <Spinner
                style={{ width: `2rem`, height: `2rem`, marginLeft: 10 }}
                color="primary"
              />
            )}
          </div>
          <p
            style={{
              padding: 0,
              margin: 0,
              marginLeft: 20,
            }}
          >
            {dataHistory.dataHistory?.descricao_medicao}
          </p>
          <p
            style={{
              padding: 0,
              marginLeft: 20,
              marginTop: 10,
              marginBottom: 20,
            }}
          >
            <i>
              Período de {dataHistory.dataHistory.periodoInicio} à{" "}
              {dataHistory.dataHistory.periodoFim}
            </i>
          </p>
          {accordions.historicoNotificacoes?.length > 0 ? (
            <Col lg="12" className="h-flex">
              {accordions.historicoNotificacoes.map((accordion) => {
                return (
                  <div key={accordion.id}>
                    <Accordion defaultActiveKey={accordion.id.toString()}>
                      <Accordion.Item eventKey={accordion.id.toString()}>
                        <Accordion.Header>
                          <h6 className="paragrafoAccordion">
                            {accordion.date_Hour} - {accordion.notification}{" "}
                          </h6>
                        </Accordion.Header>
                        <Accordion.Body>
                          <p className="paragrafoAccordion">
                            <AlertCircle size={22} />{" "}
                            {accordion.justificativa ? (
                              <>
                                {accordion.justificativa}
                                {accordion.justificavel && (
                                  <ModalJustificativaInterno
                                    rash={accordion.id.toString()}
                                  />
                                )}
                              </>
                            ) : (
                              <>
                                <span>
                                  *Usuário ainda não inseriu justificativa para
                                  este alerta*
                                </span>
                                {accordion.justificavel && (
                                  <ModalJustificativaInterno
                                    rash={accordion.id.toString()}
                                  />
                                )}
                              </>
                            )}
                            <br />
                            <AiOutlineWhatsApp size={22} />
                            <FontAwesomeIcon
                              className="align-middle mr-1"
                              icon={faCheck}
                              fixedWidth
                            />
                            {accordion?.whatsappEnviadoEm}
                            {accordion?.whatsappEnviadoEm && (
                              <FontAwesomeIcon
                                className="align-middle mr-1"
                                icon={faCheckDouble}
                                fixedWidth
                              />
                            )}
                            {accordion?.whatsappRecebidoEm}
                            {accordion?.whatsappRecebidoEm && (
                              <FontAwesomeIcon
                                className="align-middle mr-1"
                                color={"#007bff"}
                                icon={faCheckDouble}
                                fixedWidth
                              />
                            )}
                            {accordion?.whatsappLidoEm}
                            {accordion?.smsEnviadoEm && <AiOutlineMessage />}
                            {accordion?.smsEnviadoEm}
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <br />
                  </div>
                );
              })}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <Button
                  color="primary"
                  outline
                  /*  disabled={pag === 1 ? true : false} */
                  onClick={() => {
                    hanslePrevious();
                  }}
                >
                  {" < "}
                </Button>

                <Button color="primary" outline disabled>
                  {pag}
                </Button>

                <Button
                  color="primary"
                  outline
                  /*  disabled={pag === accordions.totalDePaginas ? true : false} */
                  onClick={() => {
                    handleNextPag();
                  }}
                >
                  {" > "}
                </Button>
              </div>
            </Col>
          ) : (
            <h5 style={{ textAlign: "center" }}>
              Sem notificações para o período
            </h5>
          )}
        </Row>
      </Container>
      <ScrollTop style={{ bottom: "80px" }} />
    </>
  ) : (
    <Loading width={10} height={10} color="primary" />
  );
}
