import React, { ReactNode } from "react";
import classNames from "classnames";

interface defaultProps {
  className?:string;
  children?:ReactNode;
};

const Timeline = ({ children, className, ...rest }: defaultProps) => (
  <ul className={classNames("timeline", className)} {...rest}>
    {children}
  </ul>
);

export default Timeline;
