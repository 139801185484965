/* eslint-disable no-undef */
import * as types from "../constants";

const initialState = {
  notificaWeb: false
};


export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.NOTIFICA_WEB:
      return {
        ...state,
        notificaWeb: !state.notificaWeb
      };

    default:
      return state;
  }
}
