import React, { useCallback, useEffect, useState } from "react";

import { useForm, Controller } from "react-hook-form";
import api from "../../../services/api";
import { AxiosError } from "axios";

import { Button, FormGroup, Input, Label } from "reactstrap";
import { FloatingLabel, Form } from "react-bootstrap";
import { Save, X } from "react-feather";

import { useHistory } from "react-router-dom";
import InputMask from "react-input-mask";
import { toastr } from "react-redux-toastr";
type FormData = {
  name: string;
  email: string;
  cell: string;
  status: number;
};

interface IAdminFormUpdatedUser {
  userId: number;
}
export const AdminFormUpdatedUser = ({ userId }: IAdminFormUpdatedUser) => {
  const [user, setUser] = useState({} as FormData);

  const { control, register, handleSubmit, setValue } = useForm<FormData>({
    defaultValues: {},
  });
  // const statusUser = () => {

  const history = useHistory();

  const overview = useCallback(() => {
    history.push("/overview");
  }, [history]);

  const onSubmit = ({ name, email, cell, status }: FormData) => {
    console.log({
      name,
      email,
      cell: cell.replace(/[^0-9]+/g, ""),
      status,
    });

    api
      .put(`/users/admin/profile/${userId}`, {
        name,
        email,
        cell: cell.replace(/[^0-9]+/g, ""),
        status: status,
      })
      .then(() => {
        const toastrInstance = toastr.success;
        const options = {
          timeOut: 1000 * 10,
          showCloseButton: true,
          progressBar: true,
          position: "top-right",
        };
        toastrInstance("", "Dados alterados com sucesso!.", options);
        fetchUsers();
      })
      .catch((error: AxiosError) => {
        const toastrInstance = toastr.error;
        const options = {
          timeOut: 1000 * 10,
          showCloseButton: true,
          progressBar: true,
          position: "top-right",
        };
        toastrInstance(
          "",
          "Todos os campos devem ser preenchido corretamente!",
          options
        );
        api.post("/logs/frontEnd", {
          log: JSON.stringify(error.stack),
        });
      });
  };

  const fetchUsers = useCallback(() => {
    api
      .get(`/users/admin/${userId}`)
      .then((response) => {
        const userData = response.data;
        setUser(response.data);
        // Preencha o formulário com os dados recebidos da API
        Object.keys(userData).forEach((key) => {
          // Garanta que a chave está entre as chaves permitidas
          if (
            key === "name" ||
            key === "email" ||
            key === "cell" ||
            key === "status"
          ) {
            setValue(key, userData[key]);
          }
        });
      })
      .catch((error: AxiosError) => {
        api.post("/logs/frontEnd", {
          log: JSON.stringify(error.stack),
        });
      });
  }, [userId, setValue]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <br />

      <FloatingLabel controlId="floatingInputGridName" label="Nome:">
        <Form.Control
          type="text"
          placeholder="nome"
          defaultValue={user.name}
          {...register("name", { required: true })}
        />
      </FloatingLabel>
      <br />
      <FloatingLabel controlId="floatingInputGridEmail" label="Email:">
        <Form.Control
          type="email"
          placeholder="nome@example.com"
          defaultValue={user.email}
          {...register("email")}
        />
      </FloatingLabel>
      <br />
      <InputMask
        value={user.cell}
        {...register("cell", { required: true })}
        mask="(99) 99999-9999"
        required
      >
        {(inputProps: any) => (
          <FloatingLabel controlId="floatingInputGridCell" label="Celular:">
            <Form.Control minLength={11} name="cell" type="text" />
          </FloatingLabel>
        )}
      </InputMask>
      <br />
      <FormGroup switch>
        <Controller
          name="status"
          control={control}
          defaultValue={user.status === 1 ? 1 : 0}
          render={({ field }) => (
            <>
              <Input
                type="switch"
                id="status_user"
                checked={field.value === 1 ? true : false}
                onChange={(e) => field.onChange(e.target.checked ? 1 : 0)}
              />
              <Label for="status_user">Status</Label>
            </>
          )}
        />
      </FormGroup>
      <div
        style={{
          display: "flex",

          justifyContent: "end",
        }}
      >
        <Button
          name="cancelar"
          color="secondary"
          type="button"
          className="mr-1 mb-1"
          onClick={overview}
        >
          <X className="mr-2 mb-1" size={15} />
          Cancelar
        </Button>
        <Button
          name="enviar"
          color="primary"
          type="submit"
          className="mr-1 mb-1"
        >
          <Save className="mr-2 mb-1" size={15} />
          Atualizar
        </Button>
      </div>
      <br />
    </form>
  );
};
