import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col, InputGroup, Input } from "reactstrap";
import { AiOutlineWhatsApp } from "react-icons/ai";

import { Check, AlertCircle, AlertTriangle, CloudOff } from "react-feather";

import Loading from "../../components/Loading";
import Alert from "../../components/Alert";
import CardOverview from "../../components/CardOverview";
import api from "../../services/api";
import "./styles.css";
import { useAuth } from "../../context/AuthContext";
import { useHistory } from "react-router-dom";
import { useMenu } from "../../context/MenuContext";
import { ScrollTop } from "primereact/scrolltop";
import { AxiosError } from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeaf } from "@fortawesome/free-solid-svg-icons";
interface OverviewData {
  overview: {
    pontosOK: number;
    qtdpontosComAlerta: number;
    qtdPontosComAlarme: number;
    qtdPontosEmAtraso: number;
    qtdPontosEmEconomiaDeEnergia: number;
  };
  meusPontos_EmAtraso: PropsMyPoints[] | [];
  meusPontos_ComAlerta: PropsMyPoints[] | [];
  meusPontos_ComAlarme: PropsMyPoints[] | [];
  meusPontos_OK: PropsMyPoints[] | [];
  meusPontos_EmEconomiaDeEnergia: PropsMyPoints[] | [];
}
interface PropsMyPoints {
  tipoDoPonto: string;
  nomePontoEstacao: string;
  nomeDoPonto: string;
  estacaoId: number;
  Idcryptografado: string;
  temAlerta: number;
  temAlarme: number;
  emAtraso: number;
  temOutroEstado: number;
  descricaoOutroEstado: string;
  atualizadoEm: string;
  descricaoAlerta?: string;
  descricaoAlarme?: string;
  icone?: string;
}

export default function Overview() {
  const [overview, setOverview] = useState({} as OverviewData);
  const history = useHistory();
  const { signOut } = useAuth();
  const { toggle } = useMenu();
  const [initil, setInitial] = useState(true);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const [count, setCount] = useState(0);

  const id = localStorage?.getItem("@supply:id");
  const token = localStorage.getItem("@supply:token");
  const cell = localStorage.getItem("@supply:cell");

  const device_id = localStorage.getItem("@supply:device_id");
  const plataform = localStorage.getItem("@supply:plataform");
  const versao = localStorage.getItem("@supply:version");
  const pushtoken = localStorage.getItem("@supply:pushtoken");
  const fcm = localStorage.getItem("@supply:fcm");

  const routPath = localStorage.getItem("@supply:urlPath");

  useEffect(() => {
    if (routPath && routPath!.length > 0) {
      api.post(`/logs`, {
        url: `overview - (${routPath})`,
      });
    } else {
      api.post(`/logs`, {
        url: `overview`,
      });
    }
    return () => {};
  }, [token, routPath, id]);

  useEffect(() => {
    if (cell !== null && plataform !== null && device_id !== null) {
      if (cell.length > 0 && plataform.length > 0 && device_id.length > 0) {
        api.post(`/devices`, {
          cell,
          plataform,
          device_id,
          status: 1,
          pushtoken: pushtoken && pushtoken.length > 0 ? pushtoken : null,
          app_version: versao,
        });
      }
    }
    return () => {};
  }, [token, cell, plataform, device_id, versao, pushtoken, fcm]);

  const dataOverview = useCallback(() => {
    if (initil) {
      api
        .get<OverviewData>(`/overview`)
        .then((response) => {
          setOverview(response.data);
          setCount(0);
        })
        .catch((error: AxiosError) => {
          if (count === 3) {
            api.post("/logs/frontEnd", {
              log: JSON.stringify(error.stack),
            });
            setCount(0);
            history.push("/500");
          }
          setCount(count + 1);
          dataOverview();
        })
        .finally(() => {
          setLoading(true);
          setInitial(false);
        });
      return () => {};
    } else {
      if (!toggle) {
        api
          .get<OverviewData>(`/overview`)
          .then((response) => {
            setOverview(response.data);
            setCount(0);
          })
          .catch((error: AxiosError) => {
            if (count === 3) {
              api.post("/logs/frontEnd", {
                log: JSON.stringify(error.stack),
              });
              setCount(0);
              history.push("/500");
            }
            setCount(count + 1);
            dataOverview();
          })
          .finally(() => {
            setLoading(true);
            setInitial(false);
          });
        return () => {};
      } else {
        const interval = setInterval(async () => {
          api
            .get<OverviewData>(`/overview`)
            .then((response) => {
              setOverview(response.data);
            })
            .catch((error: AxiosError) => {
              if (count === 3) {
                api.post("/logs/frontEnd", {
                  log: JSON.stringify(error.stack),
                });
                setCount(0);
                history.push("/500");
              }
              setCount(count + 1);
            })
            .finally(() => {
              setLoading(true);
              setInitial(false);
            });
        }, 5000);
        return () => clearInterval(interval);
      }
    }
  }, [setCount, count, history, initil, toggle]);

  useEffect(() => {
    dataOverview();

    return () => {};
  }, [id, signOut, dataOverview, history, token, initil, toggle]);

  useEffect(() => {
    if (
      overview.meusPontos_OK === null ||
      overview.meusPontos_OK === undefined ||
      overview.meusPontos_ComAlarme === null ||
      overview.meusPontos_ComAlarme === undefined ||
      overview.meusPontos_ComAlerta === null ||
      overview.meusPontos_ComAlerta === undefined ||
      overview.meusPontos_EmAtraso === null ||
      overview.meusPontos_EmAtraso === undefined ||
      overview.meusPontos_EmEconomiaDeEnergia === null ||
      overview.meusPontos_EmEconomiaDeEnergia === undefined
    ) {
      dataOverview();
    }
    return () => {};
  }, [overview, dataOverview]);

  const colors = ["#495057", "#4bbf73", "#d9534f", "#f0ad4e", "#4f7a28"];
  const icons = [
    <CloudOff size="25" />,
    <Check size="25" />,
    <AlertTriangle size="25" />,
    <AlertCircle size="25" />,
    <FontAwesomeIcon  icon={faLeaf} className={"faLeaf"} />,
  ];
  const message = [
    `Ops! Parece que há ${overview.overview?.qtdPontosEmAtraso} ponto(s) de monitoramento(s) com conexão atrasada.`,
    `Fique tranquilo! Todos os seus pontos de monitoramento estão OK!`,
    `ATENÇÃO!!! Há ${overview.overview?.qtdPontosComAlarme} ponto(s) de monitoramento(s) com falha(s)`,
    `ATENÇÃO!!! Há ${overview.overview?.qtdpontosComAlerta} ponto(s) de monitoramento(s) com alguma(s) não conformidade(s).`,
    `ATENÇÃO!!! Há ${overview.overview?.qtdPontosEmEconomiaDeEnergia} ponto(s) de monitoramento(s) em economia de energia.`,
  ];

  const pontosOk =
    search.length > 2
      ? overview.meusPontos_OK.filter((ponto) =>
          ponto.nomePontoEstacao.toUpperCase().includes(search.toUpperCase())
        )
      : [];

  const pontosComAlarme =
    search.length > 2
      ? overview.meusPontos_ComAlarme.filter((ponto) =>
          ponto.nomePontoEstacao.toUpperCase().includes(search.toUpperCase())
        )
      : [];

  const pontosComAlerta =
    search.length > 2
      ? overview.meusPontos_ComAlerta.filter((ponto) =>
          ponto.nomePontoEstacao.toUpperCase().includes(search.toUpperCase())
        )
      : [];

  const pontosEmAtraso =
    search.length > 2
      ? overview.meusPontos_EmAtraso.filter((ponto) =>
          ponto.nomePontoEstacao.toUpperCase().includes(search.toUpperCase())
        )
      : [];

  const pontosEmEconomiaDeEnergia =
    search.length > 2
      ? overview.meusPontos_EmEconomiaDeEnergia.filter((ponto) =>
          ponto.nomePontoEstacao.toUpperCase().includes(search.toUpperCase())
        )
      : [];

  useEffect(() => {
    if (
      overview.meusPontos_OK === null ||
      overview.meusPontos_OK === undefined ||
      overview.meusPontos_ComAlarme === null ||
      overview.meusPontos_ComAlarme === undefined ||
      overview.meusPontos_ComAlerta === null ||
      overview.meusPontos_ComAlerta === undefined ||
      overview.meusPontos_EmAtraso === null ||
      overview.meusPontos_EmAtraso === undefined ||
      overview.meusPontos_EmEconomiaDeEnergia === null ||
      overview.meusPontos_EmEconomiaDeEnergia === undefined
    ) {
      dataOverview();
    }
  }, [overview, dataOverview, plataform]);

  return (
    <>
      {loading ? (
        <div>
          <Container fluid>
            <Row>
              <div className="contentHeader">
                <h5>VISÃO GERAL</h5>

                <InputGroup
                  style={{
                    width: "10rem",
                  }}
                >
                  <Input
                    name="searchOverview"
                    placeholder="Buscar"
                    type="text"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSearch(e.target.value)
                    }
                  />
                  {/* <InputGroupAddon
                    style={{
                      height: "1.95rem",
                    }}
                    addonType="prepend"
                  >
                    &#128269;
                  </InputGroupAddon> */}
                </InputGroup>
              </div>
            </Row>
            <hr
              style={{
                backgroundColor: "GrayText",
                width: "100%",
              }}
            />

            {((search.length > 2 &&
              (pontosOk.length !== 0 ||
                pontosComAlarme.length !== 0 ||
                pontosComAlerta.length !== 0 ||
                pontosEmAtraso.length !== 0)) ||
              search.length < 3) && (
              <Row>
                <Col lg="3" className="h-flex"></Col>
                <Col lg="6" className="h-flex">
                  {overview.meusPontos_EmAtraso.length > 0 ? (
                    <a href="#EmAtraso" style={{ textDecoration: "none" }}>
                      <Alert
                        BgColor={colors[0]}
                        message={message[0]}
                        icon={icons[0]}
                      />
                    </a>
                  ) : (
                    <></>
                  )}

                  {overview.meusPontos_ComAlarme.length <= 0 &&
                  overview.meusPontos_ComAlerta.length <= 0 &&
                  overview.meusPontos_EmAtraso.length <= 0 ? (
                    <a href="#EmOk" style={{ textDecoration: "none" }}>
                      <Alert
                        BgColor={colors[1]}
                        message={message[1]}
                        icon={icons[1]}
                      />
                    </a>
                  ) : (
                    <></>
                  )}

                  {overview.meusPontos_ComAlerta.length > 0 ? (
                    <a href="#EmAlerta" style={{ textDecoration: "none" }}>
                      <Alert
                        BgColor={colors[3]}
                        message={message[3]}
                        icon={icons[3]}
                      />
                    </a>
                  ) : (
                    <></>
                  )}

                  {overview.meusPontos_ComAlarme.length > 0 ? (
                    <a href="#EmAlarme" style={{ textDecoration: "none" }}>
                      <Alert
                        BgColor={colors[2]}
                        message={message[2]}
                        icon={icons[2]}
                      />
                    </a>
                  ) : (
                    <></>
                  )}

                  {overview.meusPontos_EmEconomiaDeEnergia.length > 0 ? (
                    <a href="#EmEconomiaDeEnergia" style={{ textDecoration: "none" }}>
                      <Alert
                        BgColor={colors[4]}
                        message={message[4]}
                        icon={icons[4]}
                      />
                    </a>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col lg="3" className="h-flex"></Col>
              </Row>
            )}

            {((search.length > 2 &&
              (pontosOk.length !== 0 ||
                pontosComAlarme.length !== 0 ||
                pontosComAlerta.length !== 0 ||
                pontosEmAtraso.length !== 0)) ||
              search.length < 3) && (
              <>
                {overview.meusPontos_OK.length > 0 && (
                  <Row id="EmOk">
                    <Col lg="12">
                      <h5 style={{ padding: 0, margin: 0 }}>
                        MEUS PONTOS EM CONFORMIDADE
                      </h5>
                      <hr
                        style={{
                          backgroundColor: "GrayText",
                          width: "100%",
                          padding: 0,
                          marginTop: 2,
                        }}
                      />
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col
                    lg="12"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    {search.length > 2
                      ? pontosOk.map((myPoint, index) => (
                          <CardOverview
                            key={index}
                            icone={myPoint?.icone}
                            typePoint={myPoint?.tipoDoPonto}
                            NamePoint={myPoint?.nomeDoPonto}
                            stationId={myPoint?.estacaoId}
                            Idcryptografado={myPoint?.Idcryptografado}
                            haveAlert={myPoint?.temAlerta}
                            haveAlarm={myPoint?.temAlarme}
                            haveDelay={myPoint?.emAtraso}
                            hasOtherState={myPoint?.temOutroEstado}
                            Updated={myPoint?.atualizadoEm}
                          />
                        ))
                      : overview.meusPontos_OK.map((myPoint, index) => (
                          <CardOverview
                            key={index}
                            icone={myPoint?.icone}
                            typePoint={myPoint?.tipoDoPonto}
                            NamePoint={myPoint?.nomeDoPonto}
                            stationId={myPoint?.estacaoId}
                            Idcryptografado={myPoint?.Idcryptografado}
                            haveAlert={myPoint?.temAlerta}
                            haveAlarm={myPoint?.temAlarme}
                            haveDelay={myPoint?.emAtraso}
                            hasOtherState={myPoint?.temOutroEstado}
                            Updated={myPoint?.atualizadoEm}
                          />
                        ))}
                  </Col>
                </Row>

                {overview.meusPontos_EmEconomiaDeEnergia.length > 0 && (
                  <Row id="EmEconomiaDeEnergia">
                    <Col lg="12">
                      <h5 style={{ padding: 0, margin: 0 }}>
                        MEUS PONTOS EM ECONOMIA DE ENERGIA
                      </h5>
                      <hr
                        style={{
                          backgroundColor: "GrayText",
                          width: "100%",
                          padding: 0,
                          marginTop: 2,
                        }}
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col
                    lg="12"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    {search.length > 2
                      ? pontosEmEconomiaDeEnergia.map((myPoint, index) => (
                          <CardOverview
                            key={index}
                            icone={myPoint?.icone}
                            typePoint={myPoint?.tipoDoPonto}
                            NamePoint={myPoint?.nomeDoPonto}
                            stationId={myPoint?.estacaoId}
                            Idcryptografado={myPoint?.Idcryptografado}
                            haveAlert={myPoint?.temAlerta}
                            haveAlarm={myPoint?.temAlarme}
                            haveDelay={myPoint?.emAtraso}
                            hasOtherState={myPoint?.temOutroEstado}
                            Updated={myPoint?.atualizadoEm}
                            descricaoAlarme={myPoint?.descricaoAlarme}
                            descricaoOutroEstado={myPoint?.descricaoOutroEstado}
                          />
                        ))
                      : overview.meusPontos_EmEconomiaDeEnergia.map((myPoint, index) => (
                          <CardOverview
                            key={index}
                            icone={myPoint?.icone}
                            typePoint={myPoint?.tipoDoPonto}
                            NamePoint={myPoint?.nomeDoPonto}
                            stationId={myPoint?.estacaoId}
                            Idcryptografado={myPoint?.Idcryptografado}
                            haveAlert={myPoint?.temAlerta}
                            haveAlarm={myPoint?.temAlarme}
                            haveDelay={myPoint?.emAtraso}
                            hasOtherState={myPoint?.temOutroEstado}
                            Updated={myPoint?.atualizadoEm}
                            descricaoAlarme={myPoint?.descricaoAlarme}
                            descricaoOutroEstado={myPoint?.descricaoOutroEstado}
                          />
                        ))}
                  </Col>
                </Row>

                

                {overview.meusPontos_ComAlarme.length > 0 && (
                  <Row id="EmAlarme">
                    <Col lg="12">
                      <h5 style={{ padding: 0, margin: 0 }}>
                        MEUS PONTOS COM ALARME
                      </h5>
                      <hr
                        style={{
                          backgroundColor: "GrayText",
                          width: "100%",
                          padding: 0,
                          marginTop: 2,
                        }}
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col
                    lg="12"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    {search.length > 2
                      ? pontosComAlarme.map((myPoint, index) => (
                          <CardOverview
                            key={index}
                            icone={myPoint?.icone}
                            typePoint={myPoint?.tipoDoPonto}
                            NamePoint={myPoint?.nomeDoPonto}
                            stationId={myPoint?.estacaoId}
                            Idcryptografado={myPoint?.Idcryptografado}
                            haveAlert={myPoint?.temAlerta}
                            haveAlarm={myPoint?.temAlarme}
                            haveDelay={myPoint?.emAtraso}
                            hasOtherState={myPoint?.temOutroEstado}
                            Updated={myPoint?.atualizadoEm}
                            descricaoAlarme={myPoint?.descricaoAlarme}
                          />
                        ))
                      : overview.meusPontos_ComAlarme.map((myPoint, index) => (
                          <CardOverview
                            key={index}
                            icone={myPoint?.icone}
                            typePoint={myPoint?.tipoDoPonto}
                            NamePoint={myPoint?.nomeDoPonto}
                            stationId={myPoint?.estacaoId}
                            Idcryptografado={myPoint?.Idcryptografado}
                            haveAlert={myPoint?.temAlerta}
                            haveAlarm={myPoint?.temAlarme}
                            haveDelay={myPoint?.emAtraso}
                            hasOtherState={myPoint?.temOutroEstado}
                            Updated={myPoint?.atualizadoEm}
                            descricaoAlarme={myPoint?.descricaoAlarme}
                          />
                        ))}
                  </Col>
                </Row>

                {overview.meusPontos_ComAlerta.length > 0 && (
                  <Row id="EmAlerta">
                    <Col lg="12">
                      <h5 style={{ padding: 0, margin: 0 }}>
                        MEUS PONTOS COM ALERTA
                      </h5>
                      <hr
                        style={{
                          backgroundColor: "GrayText",
                          width: "100%",
                          padding: 0,
                          marginTop: 2,
                        }}
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col
                    lg="12"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    {search.length > 2
                      ? pontosComAlerta.map((myPoint, index) => (
                          <CardOverview
                            key={index}
                            icone={myPoint?.icone}
                            typePoint={myPoint?.tipoDoPonto}
                            NamePoint={myPoint?.nomeDoPonto}
                            stationId={myPoint?.estacaoId}
                            Idcryptografado={myPoint?.Idcryptografado}
                            haveAlert={myPoint?.temAlerta}
                            haveAlarm={myPoint?.temAlarme}
                            haveDelay={myPoint?.emAtraso}
                            hasOtherState={myPoint?.temOutroEstado}
                            Updated={myPoint?.atualizadoEm}
                            descricaoAlerta={myPoint?.descricaoAlerta}
                          />
                        ))
                      : overview.meusPontos_ComAlerta.map((myPoint, index) => (
                          <CardOverview
                            key={index}
                            icone={myPoint?.icone}
                            typePoint={myPoint?.tipoDoPonto}
                            NamePoint={myPoint?.nomeDoPonto}
                            stationId={myPoint?.estacaoId}
                            Idcryptografado={myPoint?.Idcryptografado}
                            haveAlert={myPoint?.temAlerta}
                            haveAlarm={myPoint?.temAlarme}
                            haveDelay={myPoint?.emAtraso}
                            hasOtherState={myPoint?.temOutroEstado}
                            Updated={myPoint?.atualizadoEm}
                            descricaoAlerta={myPoint?.descricaoAlerta}
                          />
                        ))}
                  </Col>
                </Row>

                {overview.meusPontos_EmAtraso.length > 0 && (
                  <Row id="EmAtraso">
                    <Col lg="12">
                      <h5 style={{ padding: 0, margin: 0 }}>
                        MEUS PONTOS COM ATRASO
                      </h5>
                      <hr
                        style={{
                          backgroundColor: "GrayText",
                          width: "100%",
                          padding: 0,
                          marginTop: 2,
                        }}
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col
                    lg="12"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    {search.length > 2
                      ? pontosEmAtraso.map((myPoint, index) => (
                          <CardOverview
                            key={index}
                            icone={myPoint?.icone}
                            typePoint={myPoint?.tipoDoPonto}
                            NamePoint={myPoint?.nomeDoPonto}
                            stationId={myPoint?.estacaoId}
                            Idcryptografado={myPoint?.Idcryptografado}
                            haveAlert={myPoint?.temAlerta}
                            haveAlarm={myPoint?.temAlarme}
                            haveDelay={myPoint?.emAtraso}
                            hasOtherState={myPoint?.temOutroEstado}
                            Updated={myPoint?.atualizadoEm}
                          />
                        ))
                      : overview.meusPontos_EmAtraso.map((myPoint, index) => (
                          <CardOverview
                            key={index}
                            icone={myPoint?.icone}
                            typePoint={myPoint?.tipoDoPonto}
                            NamePoint={myPoint?.nomeDoPonto}
                            stationId={myPoint?.estacaoId}
                            Idcryptografado={myPoint?.Idcryptografado}
                            haveAlert={myPoint?.temAlerta}
                            haveAlarm={myPoint?.temAlarme}
                            haveDelay={myPoint?.emAtraso}
                            hasOtherState={myPoint?.temOutroEstado}
                            Updated={myPoint?.atualizadoEm}
                          />
                        ))}
                  </Col>
                </Row>
              </>
            )}
            <a
              href="https://wa.me/5511932227105?text=Olá,%20gostaria%20de%20atendimento!"
              style={{
                position: "fixed",
                width: "60px",
                height: "60px",
                bottom: "10px",
                right: "10px",
                backgroundColor: "#25d366",
                color: "#FFF",
                borderRadius: "50px",
                textAlign: "center",
                fontSize: "30px",
                boxShadow: "1px 1px 2px #888",
                zIndex: 1000,
              }}
              rel="noreferrer"
              target="_blank"
            >
              <AiOutlineWhatsApp
                style={{ marginTop: "8px" }}
                className="fa fa-whatsapp"
              />
            </a>
          </Container>

          {pontosOk.length <= 0 &&
            pontosComAlarme.length <= 0 &&
            pontosComAlerta.length <= 0 &&
            pontosEmAtraso.length <= 0 &&
            search.length > 2 && (
              <Alert
                BgColor={"#84aef2"}
                message={"Ops...busca sem resultados!"}
                icon={<AlertCircle size="25" />}
              />
            )}

          <ScrollTop style={{ bottom: "80px" }} />
        </div>
      ) : (
        <Loading width={10} height={10} color="primary" />
      )}
    </>
  );
}
