import {
  createContext,
  useCallback,
  useContext,
  useState,
  ReactNode,
} from "react";
import { toastr } from "react-redux-toastr";
import api from "../services/api";

interface AuthContextState {
  tel: IsUserData;
  name: NameUserData;
  id: string;

  signIn({ cell, access_code }: UserData): Promise<void>;
  signOut(): void;
  isUser({ tel }: IsUserData): Promise<void>;
  userLogged(): boolean;
  user(): boolean;
}

interface UserData {
  cell: string;
  access_code: string;
}
interface IsUserData {
  tel: string;
}
interface NameUserData {
  name: string;
}
/* interface TokenState {
  token: string;
} */

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextState>({} as AuthContextState);

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [id, setId] = useState("");
  const [tel, setTel] = useState(() => {
    const tel = localStorage.getItem("@supply:cell");
    if (tel) {
      return { tel };
    }
    return {} as IsUserData;
  });
  const [name, setName] = useState(() => {
    const name = localStorage.getItem("@supply:name");
    if (name) {
      return { name };
    }
    return {} as NameUserData;
  });

  const signIn = useCallback(async ({ cell, access_code }: UserData) => {
    try {
      const response = await api.post("/session", {
        cell,
        access_code,
      });
      const { token } = response.data;

      const device_id = localStorage.getItem("@supply:device_id");
      const plataform = localStorage.getItem("@supply:plataform");
      const versao = localStorage.getItem("@supply:version");
      const pushtoken = localStorage.getItem("@supply:pushtoken") || null;
      localStorage.setItem("@supply:token", token);
      localStorage.setItem("@supply:toggle", "false");

      api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      if (cell !== null && plataform !== null && device_id !== null) {
        if (cell.length > 0 && plataform.length > 0 && device_id.length > 0) {
          api.put(`/devices`, {
            cell,
            plataform,
            device_id,
            status: 1,
            pushtoken: pushtoken && pushtoken.length > 0 ? pushtoken : null,
            app_version: versao,
          });
        }
      }
    } catch (error) {
      const toastrInstance = toastr.error;
      const options = {
        timeOut: 1000 * 10,
        showCloseButton: true,
        progressBar: true,
        position: "top-right",
      };
      toastrInstance("", "Usuário não autorizado!", options);
    }
  }, []);
  const signOut = useCallback(() => {
    const device_id = localStorage.getItem("@supply:device_id");
    const plataform = localStorage.getItem("@supply:plataform");
    const versao = localStorage.getItem("@supply:version");
    const cell = localStorage.getItem("@supply:cell");
    const pushtoken = localStorage.getItem("@supply:pushtoken") || null;

    if (cell !== null && plataform !== null && device_id !== null) {
      if (cell.length > 0 && plataform.length > 0 && device_id.length > 0) {
        api.put(`/devices`, {
          cell,
          plataform,
          device_id,
          status: 0,
          pushtoken: pushtoken && pushtoken.length > 0 ? pushtoken : null,
          app_version: versao,
        });
      }
    }

    localStorage.removeItem("@supply:token");
    localStorage.removeItem("@supply:name");
    localStorage.removeItem("@supply:cell");
    localStorage.removeItem("@supply:id");
    localStorage.removeItem("@supply:toggle");
  }, []);
  const isUser = useCallback(async ({ tel }: IsUserData) => {
    try {
      const response = await api.post("/users/cell", {
        cell: tel,
      });
      if (response.status === 200) {
        const { cell, name, id } = response.data;
        setId(id);
        setTel(cell);
        setName(name);
        localStorage.setItem("@supply:id", id);
        localStorage.setItem("@supply:cell", cell);
        localStorage.setItem("@supply:name", name);
      } else {
        return;
      }
    } catch (error) {
      const toastrInstance = toastr.error;
      const options = {
        timeOut: 1000 * 10,
        showCloseButton: true,
        progressBar: true,
        position: "top-right",
      };
      toastrInstance(
        "",
        "Prezado usuário(a) não foi possível realizar o acesso verifique se o número digitado foi o mesmo cadastrado caso o erro persiste entra em contato com a nossa central de atendimento.",
        options
      );
    }
  }, []);
  const userLogged = useCallback(() => {
    const token = localStorage.getItem("@supply:token");

    if (token) {
      return true;
    }
    return false;
  }, []);

  const user = useCallback(() => {
    const tel = localStorage.getItem("@supply:cell");
    if (tel) {
      return true;
    }
    return false;
  }, []);
  return (
    <AuthContext.Provider
      value={{
        id,
        tel,
        name,
        isUser,
        user,
        signIn,
        signOut,
        userLogged,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextState {
  const context = useContext(AuthContext);
  return context;
}

export { AuthProvider, useAuth };
