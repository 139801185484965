import { useState, useEffect } from "react";

import { Container, Row, Col } from "reactstrap";
import BarChart from "../../components/charts/Bar";
import Loading from "../../components/Loading";
import PieChart from "../../components/charts/Pie";
import Modal from "../../components/Modal";
import Notifications from "../../components/Notifications";

import { useHistory, useParams } from "react-router-dom";

import api from "../../services/api";
import CardStatistic from "../../components/CardStatistic";
import { ScrollTop } from "primereact/scrolltop";
import { AxiosError } from "axios";
import {  useMenu } from "../../context/MenuContext";

type justificativa = {
  id: string;
  justificavel: boolean;
  textoDaNotificacacao: string;
};
interface AlertasData {
  qtdAlertasHoje: string;
  qtdAlertasOntem: string;
  qtdAlertasMes: string;
  atualizadoEm: string;
  alertas: string[];
  alertasArray: justificativa[];
}
interface HistoricoEnergeticoData {
  VerMais: string;
  time_arr: string[];
  energizado_arr: number[];
  desenergizado_arr: number[];
  atualizadoEm: string;
}
interface PerfilEnergeticoData {
  energizado: number;
  desenergizado: number;
  atualizadoEm: string;
}
interface EquipmentData {
  nome: string;
  idSisweb: number;
}

interface StatisticData {
  dadosEquipamento: EquipmentData;
  HistoricoDeAlertas: AlertasData;
  Historico_Energetico: HistoricoEnergeticoData;
  Perfil_Energetico: PerfilEnergeticoData;
  PontosDeMedicao: [
    {
      AtualizadoEm: string;
      LabelDaMedicao: string;
      LocalDaMedicao: string;
      MaximoHistorico: number;
      MinimoHistorico: number;
      MedicaoForaDeConformidade: number;
      PontoMedicaoId: number;
      TipoMedicao: number;
      UltimaMedicao: number;
      VerMais: string;
    }
  ];
}
export default function Dashboard() {
  const { eStation, station }: { eStation: string; station: string } =
    useParams();

  const { toggle } = useMenu();

  const [statistic, setStatistic] = useState({} as StatisticData);
  const [loading, setLoading] = useState(false);

  const [countError, setCountError] = useState(0);

  const cell = localStorage.getItem("@supply:cell") || "";
  const device_id = localStorage.getItem("@supply:device_id") || "";
  const plataform = localStorage.getItem("@supply:plataform") || "";
  const versao = localStorage.getItem("@supply:version");
  const pushtoken = localStorage.getItem("@supply:pushtoken") || null;

  const history = useHistory();

  useEffect(() => {
    api
      .post(`/logs`, {
        url: `/dashboard/${station.toLowerCase()}/${eStation}`,
      })
      .catch((error: AxiosError) => {
        if (countError === 3) {
          api.post("/logs/frontEnd", {
            log: JSON.stringify(error.stack),
          });
          setCountError(0);
          history.push("/500");
        }
      });
    return () => {};
  }, [station, eStation, history, countError]);

  useEffect(() => {
    if (cell !== null && plataform !== null && device_id !== null) {
      if (cell.length > 0 && plataform.length > 0 && device_id.length > 0) {
        api.post(`/devices`, {
          cell,
          plataform,
          device_id,
          status: 1,
          pushtoken: pushtoken && pushtoken.length > 0 ? pushtoken : null,
          app_version: versao,
        });
      }
    }
    return () => {};
  }, [cell, plataform, device_id, versao, pushtoken]);

  useEffect(() => {
    api
      .get(`/dashboard/${station.toLowerCase()}/${eStation}`)
      .then((response) => {
        setStatistic(response.data);
       
      })
      .catch((error: AxiosError) => {
        history.push("/500");
        api.post("/logs/frontEnd", {
          log: JSON.stringify(error.stack),
        });
      })
      .finally(() => setLoading(true));
  }, [eStation, station, history]);

  useEffect(() => {
    if (toggle) {
      const interval = setInterval(async () => {
        api
          .get(`/dashboard/${station.toLowerCase()}/${eStation}`)
          .then((response) => {
            setStatistic(response.data);
          })
          .catch((error: AxiosError) => {
            if (countError === 3) {
              api.post("/logs/frontEnd", {
                log: JSON.stringify(error.stack),
              });
              setCountError(0);
              history.push("/500");
            }

            setCountError(countError + 1);
          })
          .finally(() => {
            setLoading(true);
          });
      }, 5 * 1000);
      return () => clearInterval(interval);
    }
  }, [statistic, eStation, station, toggle, history, countError]);

  // useEffect(() => {
  //   if (
  //     statistic.PontosDeMedicao === null ||
  //     statistic.PontosDeMedicao === undefined ||
  //     statistic.dadosEquipamento === null ||
  //     statistic.dadosEquipamento === undefined ||
  //     statistic.Perfil_Energetico === null ||
  //     statistic.Perfil_Energetico === undefined ||
  //     statistic.Historico_Energetico === null ||
  //     statistic.Historico_Energetico === undefined ||
  //     statistic.HistoricoDeAlertas === null ||
  //     statistic.HistoricoDeAlertas === undefined
  //   ) {
  //     handlerDashBoard();
  //   }
  //   return () => {};
  // }, [statistic, handlerDashBoard]);
  return (
    <>
      {loading ? (
        <>
          <Container fluid>
            <Row>
              <Col xs="auto" className=" text-left mt-n1">
                <h5>Dashboard</h5>
                <h5>{statistic.dadosEquipamento?.nome}</h5>
              </Col>
              <Col xs="auto" className="ml-auto text-right mt-n1">
                <br />
                <Modal idEquipamento={statistic.dadosEquipamento?.idSisweb} />
              </Col>
            </Row>

            <hr
              style={{
                backgroundColor: "GrayText",
                width: "100%",
                marginTop: "5px",
              }}
            />
            {statistic.PontosDeMedicao?.length > 0 ? (
              <CardStatistic
                dataApi={statistic.PontosDeMedicao}
                HistoricoDeAlertas={statistic.HistoricoDeAlertas}
              />
            ) : (
              <></>
            )}

            <Row>
              <Col lg="4" className="d-flex">
                {statistic.HistoricoDeAlertas?.alertasArray.length > 0 ? (
                  <Notifications
                    alertasArray={statistic.HistoricoDeAlertas?.alertasArray}
                  />
                ) : (
                  <></>
                )}
              </Col>
              <Col lg="4" className="d-flex">
                {statistic.Perfil_Energetico !== null ? (
                  <PieChart
                    energizado={statistic.Perfil_Energetico?.energizado}
                    desenergizado={statistic.Perfil_Energetico?.desenergizado}
                  />
                ) : (
                  <></>
                )}
              </Col>
              <Col lg="4" className="d-flex">
                {statistic.Historico_Energetico !== null ? (
                  <BarChart
                    VerMais={statistic.Historico_Energetico.VerMais}
                    energizado_arr={
                      statistic.Historico_Energetico?.energizado_arr
                    }
                    desenergizado_arr={
                      statistic.Historico_Energetico?.desenergizado_arr
                    }
                    time_arr={statistic.Historico_Energetico?.time_arr}
                  />
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Container>
          <ScrollTop style={{ bottom: "80px" }} />
        </>
      ) : (
        <Loading width={10} height={10} color="primary" />
      )}
    </>
  );
}
