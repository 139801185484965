import React, { useMemo } from "react";
import {
  Redirect,
  Route as ReactDomRoute,
  RouteProps as ReactDomRouteProps,
  useLocation,
} from "react-router-dom";

import { useAuth } from "../context/AuthContext";

interface RouteProps extends ReactDomRouteProps {
  component: React.ComponentType;
}

const RouteLogin: React.FC<RouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { userLogged } = useAuth();
  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  //http://localhost:3000/?plataforma=IOS&deviceid=E8E49E30-2CA4-46C6-921E-5A58849B062D&pushtoken=45f0865d93e2d440db2985979089eba5b418a36259df47675b6bb5c8c9961c96&versao=2.0.0
  if (
    query.get("deviceid") !== null &&
    query.get("plataforma") !== null &&
    query.get("versao") !== null
  ) {
    localStorage.setItem("@supply:device_id", String(query.get("deviceid")));
    localStorage.setItem(
      "@supply:plataform",
      String(query.get("plataforma"))
    );
    localStorage.setItem("@supply:version", String(query.get("versao")));
    localStorage.setItem("@supply:fcm", String(query.get("fcm")));
    localStorage.setItem("@supply:pushtoken", String(query.get("pushtoken")));
    localStorage.setItem("@supply:urlPath", window.location.href);
  }


  return (
    <ReactDomRoute
      {...rest}
      render={() => {
        return userLogged() ? (
          <Redirect to={{ pathname: !userLogged() ? "/" : "/overview" }} />
        ) : (
          <Component />
        );
      }}
    />
  );
};

export default RouteLogin;
