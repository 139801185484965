import React, { useRef } from "react";
import { Card, CardBody } from "reactstrap";

import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
require("highcharts/modules/exporting")(Highcharts);

interface FaseProps {
  props?: HighchartsReact.Props;
  estacao: string;
  timeStamp_Array?: string[];
  whatsApp_Array: number[];
  sms_Array: number[];
  app_Array: number[];
}

export const HistoryNotifications = ({
  estacao,
  timeStamp_Array,
  whatsApp_Array,
  sms_Array,
  app_Array,
  props,
}: FaseProps) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const device_id = localStorage.getItem("@supply:device_id");
  const plataform = localStorage.getItem("@supply:plataform");
  Highcharts.setOptions({
    lang: {
      loading: "Aguarde...",
      months: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      weekdays: [
        "Domingo",
        "Segunda",
        "Terça",
        "Quarta",
        "Quinta",
        "Sexta",
        "Sábado",
      ],
      shortMonths: [
        "Jan",
        "Feb",
        "Mar",
        "Abr",
        "Maio",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
      rangeSelectorFrom: "De",
      rangeSelectorTo: "Até",
      rangeSelectorZoom: "Periodo",
      downloadPNG: "Download imagem PNG",
      downloadJPEG: "Download imagem JPEG",
      downloadPDF: "Download documento PDF",
      downloadSVG: "Download imagem SVG",
    },
  });
  const options: Highcharts.Options = {
    title: {
      text: "Histórico de Notificações",
    },
    subtitle: {
      text: estacao,
    },
    credits: {
      enabled: true,
      text: "appsupply",
      href: "https://painel.appsupply.ml/",
    },

    tooltip: {
      pointFormat:
        '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
      shared: true,
      footerFormat: "<br/><b>Total:  {point.total} </b>",
    },
    plotOptions: {
      column: {
        stacking: "normal",
      },
    },
    navigation: {
      buttonOptions: {
        enabled: true,
      },
    },
    series: [
      {
        name: "WhatsApp",
        type: "column",
        data: whatsApp_Array.sort(),
      },
      {
        name: "App",
        type: "column",
        data: app_Array.sort(),
      },
      {
        name: "SMS",
        type: "column",
        data: sms_Array.sort(),
      },
    ],
    legend: {
      enabled: true,
    },
    xAxis: {
      // categories: timeStamp_Array,

      type: "datetime",
      dateTimeLabelFormats: {
        millisecond: "%e %B %Y",
      },
    },
    yAxis: {
      opposite: false,
      min: 0,
      title: {
        text: "Quantidade de Notificações",
        align: "middle",
      },
    },
  };
  const optionsMobile: Highcharts.Options = {
    title: {
      text: "Histórico de Notificações",
    },
    subtitle: {
      text: estacao,
    },
    credits: {
      enabled: true,
      text: "appsupply",
      href: "https://painel.appsupply.ml/",
    },

    tooltip: {
      pointFormat:
        '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
      shared: true,
      footerFormat: "<br/><b>Total:  {point.total} </b>",
    },
    plotOptions: {
      column: {
        stacking: "normal",
      },
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    series: [
      {
        name: "WhatsApp",
        type: "column",
        data: whatsApp_Array.sort(),
      },
      {
        name: "App",
        type: "column",
        data: app_Array.sort(),
      },
      {
        name: "SMS",
        type: "column",
        data: sms_Array.sort(),
      },
    ],
    legend: {
      enabled: true,
    },
    xAxis: {
      // categories: timeStamp_Array,

      type: "datetime",
      dateTimeLabelFormats: {
        millisecond: "%e %B %Y",
      },
    },
    yAxis: {
      opposite: false,
      min: 0,
      title: {
        text: "Quantidade de Notificações",
        align: "middle",
      },
    },
  };
  return (
    <>
      <Card style={{ width: "100%" }}>
        <CardBody>
          <div className="chart">
            <HighchartsReact
              highcharts={Highcharts}
              constructorType={"stockChart"}
              options={
                plataform === null && device_id === null
                  ? options
                  : optionsMobile
              }
              ref={chartComponentRef}
              {...props}
            />
          </div>
        </CardBody>
      </Card>
    </>
  );
};
