import React, { ReactNode } from "react";
import classNames from "classnames";

interface defaultProps {
  children?: ReactNode;
  className?: string;
}

export const TimelineItem = ({
  children,
  className,
  ...rest
}: defaultProps) => (
  <li className={classNames("timeline-item", className)} {...rest}>
    {children}
  </li>
);
