import { useEffect } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { toastr } from "react-redux-toastr";

//Pages
import IsUser from "../pages/IsUser";
import AcessCode from "../pages/AcessCode";
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import Overview from "../pages/Overview";
import { TimeLine } from "../pages/TimeLine";
import Profile from "../pages/Profile";
import { Justificativa } from "../pages/Justificativa";
import { DataNotification } from "../pages/DataNotification";
import DataHistory from "../pages/DataHistory";

//Rotas
import PrivateRoutes from "./PrivateRoutes";
import PrivateRoutesToken from "./PrivateRoutesToken";
import RouteLogin from "./RouteLogin";

//Componentes
import Sidebar from "../components/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/Navbar";
import Wrapper from "../components/Wrapper";
import Content from "../components/Content";
import Footer from "../components/Footer";

//Context
import { useMenu } from "../context/MenuContext";
import { useAuth } from "../context/AuthContext";
import { ToastProvider } from "../context/ToastContext";
import { ModalJustificativaProvider } from "../context/ModalJustificativaContext";
import HaveAcessCode from "../pages/HaveAcessCode";
import { Users } from "../pages/Users";
import { InternalErrorServer } from "../pages/InternalErrorServer";
import { AnaliseConsolidada } from "../pages/AnaliseConsolidada";

// import { detectarTipoDispositivo } from "../services/dispositivo";

// import Logo from "../assets/logo/sm_transp_mini.png";

export default function Routes() {
  const { toggle } = useMenu();
  const { userLogged } = useAuth();
  useEffect(() => {
    if (userLogged()) {
      if (toggle) {
        toastr.info("", "Atualização dos dados automaticamente ativada.");
      } else {
        toastr.info(
          "",
          "ATENÇÃO! Atualização dos dados automaticamente foi desativada."
        );
      }
    }
  }, [toggle, userLogged]);
  return (
    <Router>
      <Switch>
        <RouteLogin path="/" exact component={IsUser} />
        <PrivateRoutesToken path="/500" component={InternalErrorServer} />
        <PrivateRoutesToken path="/acess" component={AcessCode} />
        <PrivateRoutesToken path="/haveAcessCode" component={HaveAcessCode} />
        <RouteLogin path="/login" component={Login} />
        <ToastProvider>
          <Wrapper isBoxed={false}>
            <Sidebar />
            <Main className={""}>
              {/* {detectarTipoDispositivo() === "Android" && ""}
              <div className="banner">
                <div className="banner-content">
                  <div className="left-content">
                    <div className="logo">
                      <img width={50} src={Logo} alt="Sua Imagem" />
                    </div>
                    <div className="descricao">
                      <h3>Baixe nosso App</h3>
                      <p>Experimente o nosso incrível aplicativo agora!</p>
                    </div>
                  </div>
                  <div className="right-content">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.app.supply"
                      className="download-button"
                      target="_blank" rel="noreferrer"
                    >
                      Baixar Agora
                    </a>

                    <button className="download-button">x</button>
                  </div>
                </div>
              </div>

              {detectarTipoDispositivo() === "iOS" && "iOS"} */}
              <Navbar />
              <Content>
                <ModalJustificativaProvider>
                  <PrivateRoutes path="/overview" component={Overview} />

                  <PrivateRoutes path="/listUsers" component={Users} />

                  <PrivateRoutes
                    path="/analiseConsolidada/:station/:eStation"
                    component={AnaliseConsolidada}
                  />

                  <PrivateRoutes
                    path="/notificationHistory/:eStation"
                    component={DataNotification}
                  />

                  <PrivateRoutes
                    path="/dashboard/:station/:eStation"
                    component={Dashboard}
                    exact
                  />
                  <PrivateRoutes
                    path="/dataHistory/:eStation/:typeMeasurement"
                    component={DataHistory}
                    exact
                  />

                  <PrivateRoutes
                    path="/profile"
                    role="ROLE_USER"
                    component={Profile}
                    exact
                  />
                  <PrivateRoutes
                    path="/justificativa/:rash"
                    component={Justificativa}
                    exact
                  />
                  <PrivateRoutes path="/timeline" component={TimeLine} exact />
                </ModalJustificativaProvider>
              </Content>
              <Footer />
            </Main>
          </Wrapper>
        </ToastProvider>
      </Switch>
    </Router>
  );
}
