import { Col, Card, CardBody, Media, Row } from "reactstrap";
import { Zap, Thermometer, Droplet, Bell, Wind } from "react-feather";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import "./styles.css";

type CardStatisticProps = {
  dataApi: [
    {
      AtualizadoEm?: string;
      LabelDaMedicao?: string;
      UltimaParada?: string;
      UltimoRetorno?: string;
      LocalDaMedicao?: string;
      EstadoDeOperacao?: string;
      MaximoHistorico?: number;
      MinimoHistorico?: number;
      MedicaoForaDeConformidade?: number;
      PontoMedicaoId?: number;
      TipoMedicao?: number;
      UltimaMedicao?: number;
      VerMais?: string;
    }
  ];
  HistoricoDeAlertas: {
    qtdAlertasHoje: string;
    qtdAlertasMes: string;
    qtdAlertasOntem: string;
  };
};

export default function CardStatistic({
  dataApi,
  HistoricoDeAlertas,
}: CardStatisticProps) {
  const { eStation }: { eStation: string } = useParams();
  /*   const colors = ["#495057", "#4bbf73", "#d9534f", "#f0ad4e"]; */
  return (
    <Row>
      {dataApi.map((item, index) => {
        return (
          <Col md="6" xl key={index}>
            <Card
              className="flex-fill"
              style={{
                height: "20rem",
              }}
            >
              <CardBody className="py-4">
                <Media>
                  <div className="d-inline-block mt-2 mr-3">
                    {item.LabelDaMedicao === "Fase R" && (
                      <>
                        <Zap className="feather-lg text-danger" />
                        <br />
                        {/*  <Check
                          size="40"
                          color={colors[1]}
                          className="iconCheck"
                        /> */}
                      </>
                    )}

                    {item.LabelDaMedicao === "Fase S" && (
                      <>
                        <Zap className="feather-lg text-warning" />
                        <br />
                        {/*  <Check
                          size="40"
                          color={colors[1]}
                          className="iconCheck"
                        /> */}
                      </>
                    )}

                    {item.LabelDaMedicao === "Fase T" && (
                      <>
                        <Zap
                          className="feather-lg"
                          style={{ color: "#fd7e14" }}
                        />
                        <br />
                        {/*  <Check
                          size="40"
                          color={colors[1]}
                          className="iconCheck"
                        /> */}
                      </>
                    )}

                    {item.LabelDaMedicao === "Temperatura" && (
                      <>
                        <Thermometer className="feather-lg text-success" />
                        <br />
                        {/*  <Check
                          size="40"
                          color={colors[1]}
                          className="iconCheck"
                        /> */}
                      </>
                    )}

                    {item.LabelDaMedicao === "Umidade" && (
                      <>
                        <Droplet className="feather-lg text-info" />
                        <br />
                        {/*  <Check
                          size="40"
                          color={colors[1]}
                          className="iconCheck"
                        /> */}
                      </>
                    )}

                    {item.LabelDaMedicao === "Alerta" && (
                      <>
                        <Bell className="feather-lg text-danger" />
                        <br />
                        {/*  <Check
                          size="40"
                          color={colors[1]}
                          className="iconCheck"
                        /> */}
                      </>
                    )}
                    {item.LabelDaMedicao === "Status de Operacao" && (
                      <>
                        <Wind className="feather-lg text-info" />
                        <br />
                        {/*  <Check
                          size="40"
                          color={colors[1]}
                          className="iconCheck"
                        /> */}
                      </>
                    )}
                  </div>
                  <Media body>
                    <h3 className="mb-2">
                      {item?.UltimaMedicao === 1024
                        ? "--"
                        : item?.UltimaMedicao}
                      {(item.LabelDaMedicao === "Fase R" ||
                        item.LabelDaMedicao === "Fase S" ||
                        item.LabelDaMedicao === "Fase T") &&
                        "A"}
                      {item.LabelDaMedicao === "Temperatura" && "°C"}
                      {item.LabelDaMedicao === "Umidade" && "%"}
                    </h3>
                    {item.LabelDaMedicao === "Status de Operacao" && (
                      <div className="mb-1">
                        {item.EstadoDeOperacao === "FUNCIONANDO" && (
                          <p
                            style={{
                              marginBottom: -25,
                              fontSize: 18,
                              color: "#4BBF73 ",
                            }}
                          >
                            <b>{item?.EstadoDeOperacao}</b>
                          </p>
                        )}

                        {item.EstadoDeOperacao === "EM FALHA" && (
                          <p
                            style={{
                              marginBottom: -25,
                              fontSize: 18,
                              color: "#d9534f",
                            }}
                          >
                            <b>{item?.EstadoDeOperacao}</b>
                          </p>
                        )}
                        {item.EstadoDeOperacao === "ECONOMIA DE ENERGIA" && (
                          <p
                            style={{
                              marginBottom: -25,
                              fontSize: 18,
                              color: "#AAA",
                            }}
                          >
                            <b>{item?.EstadoDeOperacao}</b>
                          </p>
                        )}
                        <br />
                      </div>
                    )}

                    <div className="mb-0">{item?.LabelDaMedicao}</div>
                    <div className="mb-0">{item?.LocalDaMedicao}</div>

                    <div style={{ marginTop: 10, padding: 0 }}>
                      <p
                        style={{
                          fontSize: "12px",
                          color: "GrayText",
                          justifySelf: "left",
                          marginBottom: 10,
                          padding: 0,
                        }}
                      >
                        {(item.LabelDaMedicao === "Fase R" ||
                          item.LabelDaMedicao === "Fase S" ||
                          item.LabelDaMedicao === "Fase T") &&
                          "Max: " + item?.MaximoHistorico + "A"}
                        {item.LabelDaMedicao === "Temperatura" &&
                          "Max: " + item?.MaximoHistorico + "°C"}

                        {item.LabelDaMedicao === "Umidade" &&
                          "Max: " + item?.MaximoHistorico + "%"}

                        {item.LabelDaMedicao === "Status de Operacao" &&
                          "Última Parada: " + item?.UltimaParada}
                      </p>

                      <p
                        style={{
                          fontSize: "12px",
                          color: "GrayText",
                          justifySelf: "left",
                          marginBottom: 10,
                          padding: 0,
                        }}
                      >
                        {item.LabelDaMedicao === "Status de Operacao" &&
                          "Último Retorno:" + item?.UltimoRetorno}
                      </p>

                      <p
                        style={{
                          fontSize: "12px",
                          color: "GrayText",
                          justifySelf: "left",
                          marginTop: 0,
                          marginBottom: 10,
                          padding: 0,
                          lineHeight: 0,
                        }}
                      >
                        {(item.LabelDaMedicao === "Fase R" ||
                          item.LabelDaMedicao === "Fase S" ||
                          item.LabelDaMedicao === "Fase T") &&
                          "Min: " + item?.MinimoHistorico + "A"}
                        {item.LabelDaMedicao === "Temperatura" &&
                          "Min: " + item?.MinimoHistorico + "°C"}
                        {item.LabelDaMedicao === "Umidade" &&
                          "Min: " + item?.MinimoHistorico + "%"}
                      </p>
                      {item.LabelDaMedicao !== "Status de Operacao" && <br />}
                      <p
                        style={{
                          fontSize: "12px",
                          color: "GrayText",
                        }}
                      >
                        Atualizado {item?.AtualizadoEm}
                      </p>
                      {item.LabelDaMedicao !== "Status de Operacao" && (
                        <Link to={`/dataHistory/${eStation}/${item?.VerMais}`}>
                          <p
                            style={{
                              width: "100%",
                              textAlign: "right",
                              fontSize: "12px",
                            }}
                          >
                            [Ver mais]
                          </p>
                        </Link>
                      )}
                      {/* {item.LabelDaMedicao !== "Status de Operacao" && <br />} */}
                      {item.LabelDaMedicao === "Status de Operacao" && (
                        <div
                          style={{
                            marginBottom: 25,
                          }}
                        ></div>
                      )}
                    </div>
                  </Media>
                </Media>
              </CardBody>
            </Card>
          </Col>
        );
      })}

      <Col md="6" xl>
        <Card
          className="flex-fill"
          style={{
            height: "20rem",
          }}
        >
          <CardBody className="py-4">
            <Media>
              <div className="d-inline-block mt-2 mr-3">
                <Bell className="feather-lg " />
              </div>
              <Media body>
                <h3 className="mb-2">0{HistoricoDeAlertas?.qtdAlertasHoje}</h3>
                <div className="mb-0">Alertas hoje</div>
                <div className="mb-0">Geral</div>
                <div style={{ marginTop: 10, padding: 0 }}>
                  <p
                    style={{
                      fontSize: "12px",
                      color: "GrayText",
                      justifySelf: "left",
                      marginBottom: 10,
                      padding: 0,
                    }}
                  >
                    Ontem: {HistoricoDeAlertas?.qtdAlertasOntem}
                  </p>
                  <br />
                  <p
                    style={{
                      fontSize: "12px",
                      color: "GrayText",
                      justifySelf: "left",
                      marginTop: 0,
                      marginBottom: 10,
                      padding: 0,
                      lineHeight: 0,
                    }}
                  >
                    Mês: {HistoricoDeAlertas?.qtdAlertasMes}
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      color: "GrayText",
                      marginBottom: 2,
                    }}
                  >
                    Atualizado
                  </p>
                  <br />
                  <br />
                </div>
              </Media>
            </Media>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}
