import React, { useEffect, useState } from "react";
import { Sliders as SlidersIcon } from "react-feather";
import {
  faTachometerAlt,
  faSignOutAlt,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import PerfectScrollbar from "react-perfect-scrollbar";
import { useMenu } from "../../context/MenuContext";
import Logo from "../../assets/logo/sm_transp_mini.png";
import api from "../../services/api";

import { Link } from "react-router-dom";
import { Collapse } from "reactstrap";
import { useAuth } from "../../context/AuthContext";

interface DataRoutes {
  tipo: string;
  ponto: string;
  rota: string;
}
interface PropsItensSidebar {
  typeSatations: string[];
  rotas: DataRoutes[];
}
const Sidebar = () => {
  const { isOpen, isSticky } = useMenu();
  const [itensSidebar, setItensSidebar] = useState<PropsItensSidebar>();
  const [openRoutes, setOpenRoutes] = useState([]);
  const { toggleSidebar } = useMenu();
  const { signOut } = useAuth();

  const toggle = (index: number) => {
    setOpenRoutes((openRoutes) =>
      Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
    );
  };

  const token = localStorage.getItem("@supply:token");
  useEffect(() => {
    api.get<PropsItensSidebar>("/menu").then((response) => {
      setItensSidebar(response?.data);
    });
  }, [token]);

  const scroll = () => {
    toggleSidebar();
    window.scroll(0, 0);
  };

  return (
    <nav
      className={
        "sidebar" +
        (isOpen ? " toggled" : "") +
        (isSticky ? " sidebar-sticky" : "")
      }
    >
      <div className="sidebar-content">
        <PerfectScrollbar>
          <a
            className="sidebar-brand"
            href="/"
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "cente",
            }}
          >
            <img src={Logo} style={{ width: 60 }} alt="AppSupply" />{" "}
          </a>
          <br />
          <br />

          <ul className="sidebar-nav">
            <li className="sidebar-item " key="adscda">
              <Link to="/" className="sidebar-link" onClick={scroll}>
                <FontAwesomeIcon icon={faEye} />
                <span className="align-middle">Overview</span>
              </Link>
            </li>

            <li className="sidebar-item " key="adscdaqwrfe">
              <Link to="/timeline" className="sidebar-link" onClick={scroll}>
                <FontAwesomeIcon icon={faTachometerAlt} />
                <span className="align-middle">Timeline</span>
              </Link>
            </li>
            <hr
              style={{
                background: "#adb5bd",
                marginLeft: "1rem",
                marginRight: "1rem",
              }}
            />
            {itensSidebar?.typeSatations.map((item, index) => {
              return (
                <li className={"sidebar-item "} key={"BB" + index.toString()}>
                  <span
                    data-toggle="collapse"
                    className={
                      "sidebar-link " + (!openRoutes[index] ? "collapsed" : "")
                    }
                    onClick={() => toggle(index)}
                    aria-expanded={openRoutes[index] ? "true" : "false"}
                  >
                    <SlidersIcon size={18} className="align-middle mr-3" />
                    <span className="align-middle">{item}</span>
                  </span>
                  <Collapse isOpen={openRoutes[index]}>
                    <ul id="item" className="sidebar-dropdown list-unstyled">
                      {itensSidebar?.rotas.map((itemSidebar, index) => {
                        if (itemSidebar.tipo === item) {
                          return (
                            <li
                              className={"sidebar-item "}
                              key={"aa" + index.toString()}
                            >
                              <Link
                                to={itemSidebar.rota}
                                className="sidebar-link"
                                key={index}
                                onClick={scroll}
                              >
                                {/* <FontAwesomeIcon  icon={faTachometerAlt} /> */}
                                {itemSidebar.ponto}
                              </Link>
                            </li>
                          );
                        }
                        return <div key={index.toString()}></div>;
                      })}
                    </ul>
                  </Collapse>
                </li>
              );
            })}
          </ul>

          <div>
            <div>
              <ul className="sidebar-nav">
                <hr
                  style={{
                    background: "#adb5bd",
                    marginLeft: "1rem",
                    marginRight: "1rem",
                  }}
                />
                <Link
                  to="/"
                  className="sidebar-link"
                  onClick={() => signOut()}
                  key={"sdfsdgfib"}
                >
                  <span className="sidebar-link ">
                    <FontAwesomeIcon
                      icon={faSignOutAlt}
                      className="text-success"
                    />{" "}
                    <span className="align-middle">Sair</span>
                  </span>
                </Link>
              </ul>
            </div>
          </div>
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default Sidebar;
