import React, { useCallback } from "react";
import { useAuth } from "../../context/AuthContext";
import { useMenu } from "../../context/MenuContext";
import {
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  FormGroup,
  Label,
} from "reactstrap";

import { useHistory } from "react-router-dom";

import { Settings, User, RefreshCcw, Home } from "react-feather";
// import CanRole from "../CanRole/CanRole";

export default function NavbarComponent() {
  const history = useHistory();
  const { signOut } = useAuth();
  const { toggleSidebar, handleToggle, toggle } = useMenu();

  const sair = useCallback(() => {
    signOut();
    history.push("/");
  }, [signOut, history]);

  const profile = useCallback(() => {
    history.push("/profile");
  }, [history]);

  return (
    <Navbar color="white" light expand>
      <span
        className="sidebar-toggle d-flex mr-2"
        onClick={() => {
          toggleSidebar();
        }}
      >
        <i className="hamburger align-self-center" />
      </span>

      <span
        className="sidebar-toggle d-flex mr-2"
        style={{ marginTop: -6 }}
        onClick={() => {
          history.push("/overview");
        }}
      >
        <Home size={28} />
      </span>
      <Collapse navbar>
        <Nav className="ml-auto" navbar>
          <UncontrolledDropdown nav inNavbar>
            <span className="d-inline-block d-sm-none " key={1}>
              <FormGroup switch>
                <Input
                 style={{marginTop:12}}
                  type="switch"
                  id="exampleCustomSwitch1"
                  name="customSwitch"
                  label=""
                  checked={toggle}
                  onChange={() => handleToggle()}
                />
              </FormGroup>
            </span>

            <span className="d-none d-sm-inline-block" key={4}>
              <FormGroup switch>
                <Input
                  style={{marginTop:6}}
                  type="switch"
                  id="exampleCustomSwitch2"
                  name="customSwitch"
                  checked={toggle}
                  onChange={() => handleToggle()}
                />
                <Label></Label>
              </FormGroup>
            </span>

            <RefreshCcw size={18} className="align-middle mr-3" />
            <span className="d-inline-block d-sm-none" key={2}>
              <p
                className="text-dark "
                style={{
                  fontSize: ".8rem",
                  textAlign: "left",
                  lineHeight: 1,
                  padding: 0,
                  margin: 0,
                }}
              >
                {localStorage.getItem("@supply:name")?.split(" ")[0]}
              </p>

              <p
                className="text-dark "
                style={{
                  fontSize: ".8rem",
                  textAlign: "left",
                  lineHeight: 1,
                  padding: 0,
                  margin: 0,
                }}
              >
                {localStorage.getItem("@supply:name")?.split(" ")[1]}
              </p>
            </span>
            <span className="d-inline-block d-sm-none" key={3}>
              <DropdownToggle nav caret>
                <Settings size={18} className="align-middle" />
              </DropdownToggle>
            </span>

            <span className="d-none d-sm-inline-block" key={6}>
              <DropdownToggle nav>
                &nbsp;&nbsp; &nbsp;
                <span className="text-dark">
                  {localStorage.getItem("@supply:name")}
                </span>
                &nbsp;&nbsp; &nbsp;
                <User size={18} className="align-middle mr-2" />
                <Settings size={18} className="align-middle" />
              </DropdownToggle>
            </span>
            <DropdownMenu end>
              {/* <DropdownItem>
                <User size={18} className="align-middle mr-2" />
                Perfil
              </DropdownItem> */}

              {/* <DropdownItem>
                
              </DropdownItem> */}
              <DropdownItem onClick={profile}>
                <User size={18} className="align-middle mr-2 mb-2" />
                Meus Dados
              </DropdownItem>

              {/* <CanRole roles="ROLE_MASTER">
                <DropdownItem onClick={listUsers}>
                  <AlignRight size={18} className="align-middle mr-2 mb-2" />
                  Listar Usuários
                </DropdownItem>
              </CanRole> */}

              <DropdownItem divider />

              <DropdownItem onClick={sair}>Sair</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </Navbar>
  );
}
