import React, { useRef } from "react";

import { Card, CardBody } from "reactstrap";

import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
require("highcharts/modules/exporting")(Highcharts);

interface IDataHistory {
  dataHistory: {
    nome_ambiente: string;
    descricao_medicao: string;
    periodoInicio: string;
    periodoFim: string;
    tipo: number;
    datas: string[];
    serie_A: number[];
    serie_B: number[];
    serie_C: number[];
    presetMin: number;
    presetMax: number;
  };
}

interface AnaliseProps {
  props?: HighchartsReact.Props;
  dataApi: IDataHistory;
}

export const Analise = ({ props, dataApi }: AnaliseProps) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  const device_id = localStorage.getItem("@supply:device_id");
  const plataform = localStorage.getItem("@supply:plataform");

  function convertNumToTime(number: any) {
    // Check sign of given number
    var sign = number >= 0 ? 1 : -1;

    // Set positive value of number of sign negative
    number = number * sign;

    // Separate the int from the decimal part
    var hour = Math.floor(number);
    var decpart = number - hour;

    var min = 1 / 60;
    // Round to nearest minute
    decpart = min * Math.round(decpart / min);

    var minute = Math.floor(decpart * 60) + "";

    // Add padding if need
    if (minute.length < 2) {
      minute = "0" + minute;
    }

    // Add Sign in final result
    sign = sign === 1 ? 0 : 0;

    // Concate hours and minutes
    var time = sign + hour + ":" + minute;

    return time;
  }

  const options: Highcharts.Options = {
    title: {
      text: dataApi.dataHistory?.nome_ambiente,
    },
    subtitle: {
      text: dataApi.dataHistory?.descricao_medicao,
    },
    credits: {
      enabled: true,
      text: "appsupply",
      href: "https://painel.appsupply.ml/",
    },

    tooltip: {},
    navigation: {
      buttonOptions: {
        enabled: true,
      },
    },
    rangeSelector: {
      buttons: [
        {
          type: "hour",
          count: 24,
          text: "24h",
        },
        {
          type: "day",
          count: 30,
          text: "30d",
        },
        {
          type: "day",
          count: 60,
          text: "60d",
        },

        {
          type: "all",
          text: "All",
        },
      ],
      selected: 3,
    },
    series: [
      {
        name: "Uptime",
        type: "column",
        data: dataApi.dataHistory?.serie_A.sort(),
        tooltip: {
          pointFormatter() {
            return `Uptime: ${convertNumToTime(this.y)}`;
          },
        },
      },

      {
        name: "Downtime",
        type: "column",
        data: dataApi.dataHistory?.serie_B.sort(),
        tooltip: {
          pointFormatter() {
            return `Downtime: ${convertNumToTime(this.y)}`;
          },
        },
      },

      {
        name: "Economia de energia",
        type: "column",
        data: dataApi.dataHistory?.serie_C.sort(),
        tooltip: {
          pointFormatter() {
            return `Economia de energia: ${convertNumToTime(this.y)}`;
          },
        },
      },
    ],
    legend: {
      enabled: true,
    },

    yAxis: {
      title: {
        text: "Tempo (hh:mm)",
        align: "low",
      },
      opposite: false,
    },
  };
  const optionsMobile: Highcharts.Options = {
    title: {
      text: dataApi.dataHistory?.nome_ambiente,
    },
    subtitle: {
      text: dataApi.dataHistory?.descricao_medicao,
    },
    credits: {
      enabled: true,
      text: "appsupply",
      href: "https://painel.appsupply.ml/",
    },

    tooltip: {},
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    rangeSelector: {
      buttons: [
        {
          type: "hour",
          count: 24,
          text: "24h",
        },
        {
          type: "day",
          count: 30,
          text: "30d",
        },
        {
          type: "day",
          count: 60,
          text: "60d",
        },

        {
          type: "all",
          text: "All",
        },
      ],
      selected: 3,
    },
    series: [
      {
        name: "Uptime",
        type: "column",
        data: dataApi.dataHistory?.serie_A.sort(),
        tooltip: {
          pointFormatter() {
            return `Uptime: ${convertNumToTime(this.y)}`;
          },
        },
      },

      {
        name: "Downtime",
        type: "column",
        data: dataApi.dataHistory?.serie_B.sort(),
        tooltip: {
          pointFormatter() {
            return `Downtime: ${convertNumToTime(this.y)}`;
          },
        },
      },

      {
        name: "Economia de energia",
        type: "column",
        data: dataApi.dataHistory?.serie_C.sort(),
        tooltip: {
          pointFormatter() {
            return `Economia de energia: ${convertNumToTime(this.y)}`;
          },
        },
      },
    ],
    legend: {
      enabled: true,
    },

    yAxis: {
      title: {
        text: "Tempo (hh:mm)",
        align: "low",
      },
      opposite: false,
    },
  };
  return (
    <Card style={{ width: "100%" }}>
      <CardBody>
        <div className="chart">
          <HighchartsReact
            highcharts={Highcharts}
            constructorType={"stockChart"}
            options={ 
              plataform === null && device_id === null
              ? options
              : optionsMobile}
            ref={chartComponentRef}
            {...props}
          />
        </div>
      </CardBody>
    </Card>
  );
};
