import axios from "axios";

const token = localStorage.getItem("@supply:token") || "";

axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

// const api = axios.create({
//   baseURL: "https://api.appsupply.com.br/",
// });

const api = axios.create({
  baseURL: "https://api-homolog.appsupply.com.br/",
});


// const api = axios.create({
//   baseURL: "http://localhost:4001",
// });

export default api;
