import { Interweave } from "interweave";
import React, { useEffect, useState } from "react";
import { Clipboard } from "react-feather";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
} from "reactstrap";
import api from "../../services/api";
import Loading from "../Loading";
import Timeline from "../Timeline";
import { TimelineItem } from "../TimelineItem";

import styles from "./styles.module.css";

interface justificativasProps {
  historicoDeJustificativasInseridas_arr: string[];
  id: string;
  idCryptografado: string;
  opcoesDeJustificativas: string[];
  textoDaNotificacacao: string;
  tipoDoPonto: string;
}
interface ModalListaJustificativaProps {
  rashModal?: string;
}

export const ModalHistoricoJustificativa = ({
  rashModal,
}: ModalListaJustificativaProps) => {
  const [modal, setModal] = useState(false);
  const [justificativas, setJustificativas] = useState<justificativasProps>(
    {} as justificativasProps
  );

  const [loading, setLoading] = useState(false);

  const toggle = () => setModal(!modal);
  const dataAtual = new Date();
  dataAtual.setDate(dataAtual.getDay() - 32);

  useEffect(() => {
    api
      .post(`/justificativa/${rashModal}`)
      .then((response) => {
        setJustificativas(response.data);
      })
      .finally(() => setLoading(true));
  }, [rashModal]);

  return loading ? (
    <>
      <Button color="link" onClick={toggle}>
        <>
          <UncontrolledTooltip placement="top" target={String("historico1")}>
            <Interweave content={"Histórico"} />
          </UncontrolledTooltip>
          <span
            style={{ textDecoration: "underline", color: "blue" }}
            id={String("historico1")}
          >
            <Clipboard color="gray" />
          </span>
        </>
      </Button>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>
          Histórico de Justificativas do Alerta
        </ModalHeader>
        <ModalBody>
          <div className={styles.modal}>
            <Timeline className="mt-2">
              {justificativas.historicoDeJustificativasInseridas_arr?.length >
              0 ? (
                justificativas.historicoDeJustificativasInseridas_arr?.map(
                  (historico, index) => (
                    <div key={index + 1}>
                      <TimelineItem >
                        <p>{historico}</p>
                      </TimelineItem>
                    </div>
                  )
                )
              ) : (
                <TimelineItem key={0}>
                  <p>Não houve justificativa</p>
                </TimelineItem>
              )}
            </Timeline>
          </div>
        </ModalBody>
      </Modal>
    </>
  ) : (
    <Loading width={10} height={10} color="primary" />
  );
};
