import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";

interface ModalJustificativaContextState {
  isOpen: boolean;
  toggleModalJustificativa(): void;

}

interface ModalJustificativaProps {
  children: ReactNode;
}

const ModalJustificativaContext = createContext<ModalJustificativaContextState>(
  {} as ModalJustificativaContextState
);

const ModalJustificativaProvider = ({ children }: ModalJustificativaProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModalJustificativa = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <ModalJustificativaContext.Provider
      value={{ isOpen, toggleModalJustificativa }}
    >
      {children}
    </ModalJustificativaContext.Provider>
  );
};

function useModalJustificativa(): ModalJustificativaContextState {
  const context = useContext(ModalJustificativaContext);
  return context;
}

export { ModalJustificativaProvider, useModalJustificativa };
