import React, { FormEvent, useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Input,
  Col,
  Card,
  CardBody,
  Row,
  Label,
} from "reactstrap";
import { Edit } from "react-feather";
import { toastr } from "react-redux-toastr";
import api from "../../services/api";
import { AxiosError } from "axios";
import { ModalHistoricoJustificativa } from "../ModalHistoricoJustificativa";
import Loading from "../Loading";

interface ModalProps {
  rash?: string;
  isRedirect?: boolean;
}

interface justificativasProps {
  historicoDeJustificativasInseridas_arr: string[];
  id: string;
  idCryptografado: string;
  opcoesDeJustificativas: string[];
  textoDaNotificacacao: string;
  tipoDoPonto: string;
}
export const ModalJustificativaInterno = ({
  rash,
  isRedirect = false,
}: ModalProps) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const dataAtual = new Date();
  dataAtual.setDate(dataAtual.getDay() - 32);
  const [outra, setOutra] = useState(false);
  const [userResponse, setUserResponse] = useState("");

  const [loading, setLoading] = useState(false);

  const [justificativas, setJustificativas] = useState<justificativasProps>(
    {} as justificativasProps
  );

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    try {
      if (userResponse !== "") {
        api
          .post(`/justificativa/justification/${rash}`, {
            justification: userResponse,
          })
          .then(() => {
            toastr.info("Sucesso", "Justificativa inserida com sucesso!");
            toggle();
          })
          .catch((error: AxiosError) =>
            toastr.error("Error", error.response?.data.message)
          )
          .finally(() => setLoading(true));
      } else {
        toastr.warning("Alerta", "Todos os campos precisam ser preenchidos!");
      }
    } catch (error) {
      toastr.error("Error", "Error ao inserir ");
    }
  }

  function selectOutro() {
    setOutra(true);
  }
  useEffect(() => {
    api
      .post(`/justificativa/${rash}`)
      .then((response) => setJustificativas(response.data))
      .finally(() => setLoading(true));
  }, [rash]);

  return loading ? (
    <>
      {isRedirect ? (
        <Button color="primary" className="mr-1 mb-1" onClick={toggle} outline>
          <Edit style={{ width: "1rem", marginTop: "-5px" }} /> Justificar
        </Button>
      ) : (
        <Button color="link" onClick={toggle}>
          <Edit style={{ width: "1rem", marginTop: "-5px" }} />
        </Button>
      )}

      <Modal isOpen={modal} toggle={toggle} size="md">
        <ModalHeader toggle={toggle}>Justificativa</ModalHeader>
        <ModalBody>
          <Row sm={12} style={{ justifyContent: "center" }}>
            <Card style={{ width: "50rem" }}>
              <CardBody>
                <form onSubmit={handleSubmit}>
                  <Col sm={12}>
                    <p
                      style={{
                        marginLeft: -10,
                      }}
                    >
                      <b>Alerta:</b>
                    </p>
                    <p
                      style={{
                        marginLeft: -10,
                        textAlign: "justify",
                      }}
                    >
                      {justificativas.textoDaNotificacacao}
                    </p>
                  </Col>
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "right",
                    }}
                    sm={12}
                  >
                    <ModalHistoricoJustificativa rashModal={rash} />
                  </Col>

                  <Col sm={12}>
                    <p
                      style={{
                        marginLeft: -10,
                      }}
                    >
                      <b>Escolha a opção que descreve o ocorrido:</b>
                    </p>
                  </Col>

                  <FormGroup row>
                    <Col sm={12}>
                      <div className="custom-controls-stacked">
                        {justificativas.opcoesDeJustificativas?.map(
                          (opcaoJustificativa, index) => (
                            <FormGroup check>
                              <Input
                                type="radio"
                                key={index}
                                onChange={() => setOutra(false)}
                                className="radioButton"
                                id={`justificativa-${index}`}
                                name="justificativa"
                                value={opcaoJustificativa}
                                onClick={() =>
                                  setUserResponse(opcaoJustificativa)
                                }
                              />
                              <Label>{opcaoJustificativa}</Label>
                            </FormGroup>
                          )
                        )}

                        <FormGroup check>
                          <Input
                            type="radio"
                            className="radioButton"
                            id="justificativa-100"
                            name="justificativa"
                            value="Outra"
                            onClick={() => selectOutro()}
                          />
                          <Label>Outra</Label>
                        </FormGroup>
                      </div>
                    </Col>
                    {outra && (
                      <Col sm={12}>
                        <Input
                          type="textarea"
                          className="outra"
                          id="justificativa"
                          name="justificativa"
                          required
                          onChange={(e) => setUserResponse(e.target.value)}
                          maxLength={140}
                          style={{
                            width: "100%",
                            height: "4rem",
                            fontStyle: "normal",
                            textAlign: "left",
                            margin: 0,
                            padding: 0,
                            paddingLeft: 10,
                            paddingBottom: 40,
                            justifyContent: "start",
                            marginTop: 10,
                            fontSize: "1rem",
                            resize: "none",
                          }}
                          autoFocus
                        />
                      </Col>
                    )}

                    <Col sm={12}>
                      <Button
                        style={{
                          width: "100%",
                          backgroundColor: "#262F3D",
                          marginTop: 10,
                          alignItems: "center",
                          textAlign: "center",
                          justifyItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Salvar
                      </Button>
                    </Col>
                  </FormGroup>
                </form>
              </CardBody>
            </Card>
          </Row>
        </ModalBody>
      </Modal>
    </>
  ) : (
    <Loading width={10} height={10} color="primary" />
  );
};
