import { useState } from "react";
import { Button } from "reactstrap";

import { Checkbox } from "primereact/checkbox";

import { useForm } from "react-hook-form";
import api from "../../../services/api";
import { AxiosError } from "axios";
import { toastr } from "react-redux-toastr";
type FormData = {
  name: string;
  email: string;
  cell: string;
  status: boolean;
  roleUser: boolean;
  roleAdmin: boolean;
  roleMaster: boolean;
  notifica_Web: boolean;
  notifica_APP: boolean;
  notifica_SMS: boolean;
  notifica_WhatsApp: boolean;
  notifica_torpedoVoz: boolean;
};

export const UpdatedUserRoles = () => {
  const { handleSubmit, setValue } = useForm<FormData>({
    defaultValues: {
      status: false,
      notifica_APP: false,
      notifica_Web: false,
      notifica_SMS: false,
      notifica_torpedoVoz: false,
      notifica_WhatsApp: false,
    },
  });

  const [selectedRoles, setSelectedRoles] = useState<number[]>([]);

  const onSubmit = ({
    name,
    email,
    cell,
    status,
    notifica_APP,
    notifica_Web,
    notifica_SMS,
    notifica_torpedoVoz,
    notifica_WhatsApp,
    roleAdmin,
    roleMaster,
    roleUser,
  }: FormData) => {
    let newRoles = [];

    if (roleAdmin) {
      newRoles.push(3);
    }

    if (roleUser) {
      newRoles.push(4);
    }
    if (roleMaster) {
      newRoles.push(5);
    }

    if (newRoles.length > 0) {
      api
        .post(`/users/admin`, {
          name,
          email,
          cell: cell.replace(/[^0-9]+/g, ""),
          roles: newRoles,
          status: status ? 1 : 0,
          notifica_APP: notifica_APP ? 1 : 0,
          notifica_Web: notifica_Web ? 1 : 0,
          notifica_SMS: notifica_SMS ? 1 : 0,
          notifica_torpedoVoz: notifica_torpedoVoz ? 1 : 0,
          notifica_WhatsApp: notifica_WhatsApp ? 1 : 0,
        })
        .then(() => {
          const toastrInstance = toastr.success;
          const options = {
            timeOut: 1000 * 10,
            showCloseButton: true,
            progressBar: true,
            position: "top-right",
          };
          toastrInstance("", "Usuário cadastrado com sucesso!.", options);
        })
        .catch((error: AxiosError) => {
          const toastrInstance = toastr.error;
          const options = {
            timeOut: 1000 * 10,
            showCloseButton: true,
            progressBar: true,
            position: "top-right",
          };
          toastrInstance(
            "",
            "Todos os campos devem ser preenchido corretamente!",
            options
          );
          api.post("/logs/frontEnd", {
            log: JSON.stringify(error.stack),
          });
        });
    } else {
      api
        .post(`/users/admin`, {
          name,
          email,
          cell: cell.replace(/[^0-9]+/g, ""),
          status: status ? 1 : 0,
          notifica_APP: notifica_APP ? 1 : 0,
          notifica_Web: notifica_Web ? 1 : 0,
          notifica_SMS: notifica_SMS ? 1 : 0,
          notifica_torpedoVoz: notifica_torpedoVoz ? 1 : 0,
          notifica_WhatsApp: notifica_WhatsApp ? 1 : 0,
        })
        .then(() => {
          const toastrInstance = toastr.success;
          const options = {
            timeOut: 1000 * 10,
            showCloseButton: true,
            progressBar: true,
            position: "top-right",
          };
          toastrInstance("", "Usuário cadastrado com sucesso!.", options);
        })
        .catch((error: AxiosError) => {
          console.log("Erro ao cadastrar usuario");
          const toastrInstance = toastr.error;
          const options = {
            timeOut: 1000 * 10,
            showCloseButton: true,
            progressBar: true,
            position: "top-right",
          };
          toastrInstance(
            "",
            "Todos os campos devem ser preenchido corretamente!",
            options
          );
          api.post("/logs/frontEnd", {
            log: JSON.stringify(error.stack),
          });
        });
    }
  };

  const handleCheckboxChange = (roleValue: number, isChecked: boolean) => {
    if (isChecked) {
      setSelectedRoles((prevRoles) => [...prevRoles, roleValue]);
    } else {
      setSelectedRoles((prevRoles) =>
        prevRoles.filter((role) => role !== roleValue)
      );
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <label className="font-bold block mb-2">Roles do Usuário:</label>
      <div className=" field-checkbox">
        <Checkbox
          inputId="role_user"
          value={3}
          checked={selectedRoles.includes(3)}
          onChange={(e) => {
            setValue("roleUser", e.checked);
            handleCheckboxChange(3, e.checked);
          }}
        />
        <label htmlFor="role_user">Usuário</label>
      </div>
      <div className=" field-checkbox">
        <Checkbox
          inputId="role_Admin"
          value={4}
          checked={selectedRoles.includes(4)}
          onChange={(e) => {
            setValue("roleAdmin", e.checked);
            handleCheckboxChange(4, e.checked);
          }}
        />
        <label htmlFor="role_Admin">Administrador</label>
      </div>
      <div className=" field-checkbox">
        <Checkbox
          inputId="role_master"
          value={5}
          checked={selectedRoles.includes(5)}
          onChange={(e) => {
            setValue("roleMaster", e.checked);
            handleCheckboxChange(5, e.checked);
          }}
        />
        <label htmlFor="role_master">Master</label>
      </div>

      <div className="d-flex mt-4 justify-content-end">
        <Button
          type="submit"
          color="primary"
          size="md"
          className="mr-1 mb-1"
          outline
        >
          Salvar
        </Button>
        <Button color="secondary" size="md" className="mr-1 mb-1" outline>
          Cancelar
        </Button>
      </div>
    </form>
  );
};
