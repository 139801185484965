import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

import { Box, Edit2, Key, MessageCircle, User } from "react-feather";
import { Tab, Nav } from "react-bootstrap";
import { AdminFormUpdatedUser } from "../Forms/UpdatedUser";
import { UpdatedUserNotification } from "../Forms/UpdatedUserNotification";
import { UpdatedUserRoles } from "../Forms/UpdatedUserRoles";

interface IModalAdminUpdatedUser {
  userId: number;
}
export const ModalAdminUpdatedUser = ({ userId }: IModalAdminUpdatedUser) => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <>
      <Button
        onClick={toggle}
        color="primary"
        size="md"
        className="mr-1 mb-1"
        outline
        id="buttonEditUsers"
      >
        <Edit2 className="align-middle" size={15} />
      </Button>

      <Modal isOpen={modal} toggle={toggle} size="md">
        <ModalHeader toggle={toggle}>Editar Usuário</ModalHeader>
        <ModalBody>
          <Tab.Container id="left-tabs-example" defaultActiveKey="user">
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="user">
                  <User className="align-middle" size={15} />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="points">
                  <Box className="align-middle" size={15} />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="notifications">
                  <MessageCircle className="align-middle" size={15} />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="roles">
                  <Key className="align-middle" size={15} />
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="user">
                <AdminFormUpdatedUser userId={userId} />
              </Tab.Pane>
              <Tab.Pane eventKey="points">
                <h4 className="tab-title">Pontos</h4>
              </Tab.Pane>
              <Tab.Pane eventKey="notifications">
                <UpdatedUserNotification />
              </Tab.Pane>
              <Tab.Pane eventKey="roles">
                <UpdatedUserRoles />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </ModalBody>
      </Modal>
    </>
  );
};
