import { Card, CardBody, CardTitle, UncontrolledTooltip } from "reactstrap";
import {
  Clock,
  Check,
  AlertCircle,
  AlertTriangle,
  CloudOff,
} from "react-feather";
import { Interweave } from "interweave";

import "./styles.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeaf } from "@fortawesome/free-solid-svg-icons";

const colors = ["#495057", "#4bbf73", "#d9534f", "#f0ad4e"];

interface PropsMyPoints {
  typePoint: string;
  NamePoint: string;
  stationId: number;
  Idcryptografado: string;
  haveAlert: number;
  haveAlarm: number;
  haveDelay: number;
  hasOtherState: number;
  Updated: string;
  descricaoAlerta?: string;
  descricaoAlarme?: string;
  descricaoOutroEstado?: string;
  key: number;
  icone?: string;
}

export default function CardOverview({
  typePoint,
  NamePoint,
  stationId,
  Idcryptografado,
  descricaoAlerta,
  descricaoAlarme,
  descricaoOutroEstado,
  haveAlert,
  haveAlarm,
  haveDelay,
  hasOtherState,
  Updated,
  icone,
}: PropsMyPoints) {
  return (
    <div style={{ marginLeft: 5, marginRight: 5, paddingBottom: 0 }}>
      <Card
        style={{
          width: "22rem",
          paddingTop: 15,
          paddingLeft: 15,
          paddingRight: 15,
          paddingBottom: 0,
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "left",
              alignContent: "center",
              alignSelf: "center",
            }}
          >
            <img
              width="70%"
              src={"data:image/svg+xml;base64," + icone}
              alt="Card"
            />
          </div>

          <div style={{ display: "grid" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Clock size="20" style={{ marginTop: 5, marginLeft: 40 }} />
              <p style={{ fontSize: 12, marginLeft: 4, textAlign: "right" }}>
                {Updated}
              </p>
            </div>
            <div
              style={{
                display: "grid",
                paddingRight: 10,
                justifyContent: "right",
              }}
            >
              {!haveAlarm && !haveAlert && !haveDelay && !hasOtherState ? (
                <Check size="40" color={colors[1]} className="iconCheck" />
              ) : (
                <></>
              )}

              {haveAlert ? (
                <>
                  <UncontrolledTooltip
                    placement="top"
                    target={String("comAlerta" + stationId)}
                  >
                    <Interweave content={descricaoAlerta} />
                  </UncontrolledTooltip>
                  <span
                    style={{ textDecoration: "underline", color: "blue" }}
                    id={String("comAlerta" + stationId)}
                  >
                    <AlertCircle
                      size="40"
                      color={colors[3]}
                      className="iconAlertCircle"
                    />
                  </span>
                </>
              ) : (
                <></>
              )}

              {haveAlarm ? (
                <>
                  <UncontrolledTooltip
                    placement="top"
                    target={String("ComAlarme" + stationId)}
                  >
                    <Interweave content={descricaoAlarme} />
                  </UncontrolledTooltip>
                  <span
                    style={{ textDecoration: "underline", color: "blue" }}
                    id={String("ComAlarme" + stationId)}
                  >
                    <AlertTriangle
                      size="40"
                      color={colors[2]}
                      className="iconAlertTriangle"
                    />
                  </span>
                </>
              ) : (
                <></>
              )}

              {haveDelay ? (
                <CloudOff
                  size="40"
                  color={colors[0]}
                  className="iconCloudOff"
                />
              ) : (
                <></>
              )}
              {hasOtherState ? (
                <>
                  <UncontrolledTooltip
                    placement="top"
                    target={String("outroEstado" + stationId)}
                  >
                    {descricaoOutroEstado &&
                      descricaoOutroEstado.length > 0 && (
                        <Interweave content={descricaoOutroEstado} />
                      )}
                  </UncontrolledTooltip>
                  <span
                    style={{ textDecoration: "underline", color: "blue" }}
                    id={String("outroEstado" + stationId)}
                  >
                    <FontAwesomeIcon
                      color={"#4f7a28"}
                      size={"2x"}
                      icon={faLeaf}
                      className={"faLeaf"}
                    />
                  </span>
                </>
              ) : (
                <></>
              )}

              <div className="fa-bars"> </div>
            </div>
          </div>
        </div>

        <CardBody>
          <CardTitle tag="h1">
            <b>{typePoint}</b>
          </CardTitle>
          <CardTitle tag="h1">
            <b>{NamePoint}</b>
          </CardTitle>
          <Link to={`/dashboard/${typePoint}/${Idcryptografado}`}>
            <p
              style={{
                width: "100%",
                textAlign: "right",
                fontSize: "12px",
              }}
            >
              [Ver mais]
            </p>
          </Link>
        </CardBody>
      </Card>
    </div>
  );
}
