import React from "react";
import { Spinner } from "reactstrap";

interface LoadingData {
  width: number;
  height: number;
  color: string;
}
export default function Loading({ width, height, color }: LoadingData) {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spinner
        style={{ width: `${width}rem`, height: `${height}rem` }}
        color="primary"
      />
    </div>
  );
}
