import React from "react";
import { Container, Row, Col } from "reactstrap";

const Footer = () => (
  <footer className="footer" style={{ paddingTop: 5, paddingBottom:5 }}>
    <Container fluid>
      <Row className="text-muted">
        <Col xs="12" className="text-center">
          <p className="mb-0" style={{ fontSize: 10 }}>
            <span className="text-muted">AppSupply - 2021.7.19</span>
          </p>
          <p className="mb-0" style={{ fontSize: 10 }}>
            Copyright &copy; 2016 - {new Date().getFullYear()}
          </p>
          <p className="mb-0" style={{ fontSize: 10 }}>
            Todos os direitos reservados
          </p>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
