import { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";

import { Button, Card, CardBody, Form, Input } from "reactstrap";
import supplyLogo from "../../assets/supply/logo_mini_supply.png";
import InputMask from "react-input-mask";
import { useAuth } from "../../context/AuthContext";
import { SubmitHandler, useForm } from "react-hook-form";
import { toastr } from "react-redux-toastr";
interface SignInFormData {
  access_code: string;
}

export default function HaveAcessCode() {
  const [time, setTime] = useState(1 * 60);
  const [active, setActive] = useState(true);
  const [errorLogin, setErrorLogin] = useState(false);

  useEffect(() => {
    if (active && time > 0) {
      setTimeout(() => {
        setTime(time - 1);
      }, 1000);
    }
  }, [active, time]);

  useEffect(() => {
    if (time === 0) {
      setActive(false);
    }
  }, [time]);

  const { signIn, userLogged } = useAuth();
  const history = useHistory();

  const { register, handleSubmit } = useForm<SignInFormData>({});
  const onSubmit: SubmitHandler<SignInFormData> = async ({
    access_code,
  }: SignInFormData) => {
    try {
      if (access_code.length < 6) {
        throw new Error("O código de acesso precisa possuir 6 digitos!");
      }
      const cell = localStorage.getItem("@supply:cell");
      
      if (cell) {
        if (cell?.length !== 11) {
          throw new Error("O número do celular precisa possuir 11 digítos!");
        } else {
          await signIn({ cell: cell, access_code });
          if (userLogged()) {
            setErrorLogin(false);
           
            history.push("/overview");
          }
          setErrorLogin(true);
        }
      }
    } catch (error) {
      setErrorLogin(true);
      const toastrInstance = toastr.error;
      const options = {
        timeOut: 1000 * 10,
        showCloseButton: true,
        progressBar: true,
        position: "top-right",
      };
      toastrInstance(
        "",
        "Favor inserir o código de acesso com seis digitos enviado para o seu celuar cadastrado",
        options
      );
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
        }}
      >
        <Card style={{ width: 470, marginTop: 80 }}>
          <CardBody>
            <div className="m-sm-4">
              <div className="text-center">
                <img
                  src={supplyLogo}
                  alt="Supply"
                  className="img-fluid "
                  width="132"
                  height="132"
                />
              </div>
              <div className="text-center mt-2">
                <p>Pronto!</p>
                <p>
                  Agora confirme o código de acesso
                  <br /> que você recebeu em <br />
                  {localStorage.getItem("@supply:cell")}
                </p>
              </div>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <InputMask {...register("access_code")} mask="999999">
                    {() => (
                      <Input
                        name="access_code"
                        type="tel"
                        placeholder="000000"
                        style={{
                          width: "6rem",
                          textAlign: "center",
                        }}
                      />
                    )}
                  </InputMask>
                </div>

                <Link to="/acess" style={{ textAlign: "center" }}>
                  <p> Não recebi meu código de acesso!</p>
                </Link>

                <div className="text-center mt-3">
                  <Button color="primary" size="lg">
                    Entrar
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: 20,
                    justifyContent: "center",
                  }}
                >
                  {errorLogin && (
                    <a
                      style={{
                        color: "gray",
                      }}
                      className="mr-1 mb-1"
                      rel="noreferrer"
                      href="https://wa.me/5511932227105?text=Olá, não consigo acessar com o código que recebi."
                      target="_blank"
                    >
                      <span> Ajuda</span>
                    </a>
                  )}
                </div>
              </Form>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
}
