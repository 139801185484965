import { useState, useRef, useCallback } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { File, Mail } from "react-feather";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import DatePicker from "../DataPicker";
import { format } from "date-fns";
import api from "../../services/api";
import { toastr } from "react-redux-toastr";
import Input from "../Input";

interface PropsModals {
  buttonLabe?: string;
  classNamel?: string;
  idEquipamento: number;
}

interface PropsDates {
  dtfim: string;
  dtini: string;
  emails: string;
}

const Modals = ({ idEquipamento }: PropsModals) => {
  const [modal, setModal] = useState(false);
  /*  const [loading, setLoading] = useState(false); */
  const formRef = useRef<FormHandles>(null);
  const handleSubmit = useCallback(
    async ({ dtfim, dtini, emails }: PropsDates) => {
      const obs = `Relatorio gerado por ${localStorage.getItem(
        "@supply:name"
      )} em ${format(new Date(), "dd/MM/yyyy HH:mm:ss")} no AppSupply`;

      const dataFinal = format(new Date(dtfim), "dd/MM/yyyy");
      const dataInicial = format(new Date(dtini), "dd/MM/yyyy");
      api.post(`/relatorios/envio/${idEquipamento}`, {
        dtini: dataInicial,
        dtfim: dataFinal,
        obs,
        emails,
      });
      setModal(false);
      const options = {
        timeOut: 1000 * 10,
        showCloseButton: true,
        progressBar: true,
        position: "top-right",
      };
      const toastrInstance = toastr.warning;
      toastrInstance(
        "Atenção!",
        "Dentro de alguns minutos você receberá o relatório no(s) email(s) indicados.",
        options
      );
    },
    [idEquipamento]
  );

  const toggle = () => setModal(!modal);
  const dataAtual = new Date();
  dataAtual.setDate(dataAtual.getDay() - 32);

  return (
    <>
      <div>
        <Button color="link" onClick={toggle}>
          <File /> Relatório
        </Button>
        <Modal isOpen={modal} toggle={toggle} size="md">
          <ModalHeader toggle={toggle}>Relatório</ModalHeader>
          <ModalBody>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <div
                style={{
                  display: "grid",
                  flex: 1,
                  height: "100%",
                  justifyContent: "center",
                  alignContent: "center",
                  justifySelf: "center",
                }}
              >
                <p>Selecione o período desejado.</p>

                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <label
                    htmlFor="label_data_inicial"
                    style={{ marginLeft: "10px", marginRight: "10px" }}
                  >
                    <b>De</b>
                  </label>
                  <DatePicker
                    id="label_data_inicial"
                    name="dtini"
                    selectDate={dataAtual}
                    required
                  />
                  <label
                    htmlFor="label_data_final"
                    style={{ marginLeft: "10px", marginRight: "10px" }}
                  >
                    <b>à</b>
                  </label>
                  <DatePicker
                    id="label_data_final"
                    name="dtfim"
                    selectDate={new Date()}
                    required
                  />
                </div>

                <label htmlFor="emails">Insira o seu melhor e-mail</label>
                <Input
                  style={{
                    width: "100%",
                    background: "white",
                    marginBottom: "20px",
                    textAlign: "left",
                    fontSize: 16,
                    padding: 10,
                  }}
                  name="emails"
                  required
                />
                <p
                  style={{
                    textAlign: "left",
                    color: "red",
                    fontSize: 12,
                  }}
                >
                  Para múltiplos destinatários, separe por ;
                </p>
                <p
                  style={{
                    textAlign: "left",
                    color: "red",
                    fontSize: 12,
                    marginTop: -12,
                  }}
                >
                  Ex: eu@dominio.com;voce@dominio.com
                </p>

                <Button style={{ backgroundColor: "#262F3D" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: 1,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "50%",
                        justifyContent: "center",
                      }}
                    >
                      <Mail />
                      <p
                        style={{
                          marginLeft: 10,
                        }}
                      >
                        Enviar
                      </p>
                    </div>
                  </div>
                </Button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default Modals;
