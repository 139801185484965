import React from "react";

import "./style.css";
interface ContentProps {
  children: object;
}

const Content = ({ children }: ContentProps) => (
  <div className="content">{children}</div>
);

export default Content;
