import { useCallback, useEffect, useState } from "react";
import { ScrollTop } from "primereact/scrolltop";
import {
  Button,
  Container,
  Row,
  Card,
  Col,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import Loading from "../../components/Loading";

import { useForm, SubmitHandler } from "react-hook-form";

import api from "../../services/api";

import { toastr } from "react-redux-toastr";
import { FloatingLabel, Form } from "react-bootstrap";
import { Save, X } from "react-feather";
import InputMask from "react-input-mask";

import "./styles.css";
import { useHistory } from "react-router-dom";
import { AxiosError } from "axios";
import CanView from "../../components/CanView/CanView";

type Inputs = {
  name: string;
  email: string;
  cell: string;
  notifica_APP: boolean;
  notifica_Web: boolean;
  notifica_SMS: boolean;
  notifica_torpedoVoz: boolean;
  notifica_WhatsApp: boolean;
};

type IStatusSwitch = {
  name: string;
  email: string;
  cell: string;
  notifica_APP: number;
  notifica_Web: number;
  notifica_SMS: number;
  notifica_torpedoVoz: number;
  notifica_WhatsApp: number;
};

export default function Profile() {
  const [loading, setLoading] = useState(false);
  const [statusSwitch, setStatusSwitch] = useState({} as IStatusSwitch);
  const [user, setUser] = useState({} as Inputs);
  const [app, setApp] = useState(true);
  const [web, setWeb] = useState(true);
  const [whatsapp, setWhatsapp] = useState(true);
  const [sms, setSms] = useState(true);

  // const { handleNotificaWeb } = useMenu();

  const [voz, setVoz] = useState(true);
  const history = useHistory();
  const {
    register,
    handleSubmit,

    setValue,
  } = useForm<Inputs>({
    defaultValues: {
      notifica_APP: true,
      notifica_Web: true,
      notifica_SMS: false,
      notifica_torpedoVoz: false,
      notifica_WhatsApp: false,
    },
  });

  useEffect(() => {
    setLoading(false);
    api
      .get("/users")
      .then((response) => {
        setUser(response.data);
        setApp(user.notifica_APP);
        setWeb(user.notifica_Web);
        setSms(user.notifica_SMS);
        setWhatsapp(user.notifica_WhatsApp);
        setVoz(user.notifica_torpedoVoz);
        setLoading(true);

        setStatusSwitch(response.data);
      })
      .catch((error: AxiosError) => {
        api.post("/logs/frontEnd", {
          log: JSON.stringify(error.stack),
        });
      });
  }, [
    user.notifica_APP,
    user.notifica_Web,
    user.notifica_SMS,
    user.notifica_WhatsApp,
    user.notifica_torpedoVoz,
  ]);

  const onSubmit: SubmitHandler<Inputs> = ({
    name,
    email,
    cell,
    notifica_APP,
    notifica_Web,
    notifica_SMS,
    notifica_torpedoVoz,
    notifica_WhatsApp,
  }: Inputs) => {
    api
      .put("/users", {
        name,
        email,
        cell: cell.replace(/[^0-9]+/g, ""),
        notifica_APP:
          statusSwitch.notifica_APP >= 0 ? (notifica_APP ? 1 : 0) : -1,
        notifica_Web:
          statusSwitch.notifica_Web >= 0 ? (notifica_Web ? 1 : 0) : -1,
        notifica_SMS:
          statusSwitch.notifica_SMS >= 0 ? (notifica_SMS ? 1 : 0) : -1,
        notifica_torpedoVoz:
          statusSwitch.notifica_torpedoVoz >= 0
            ? notifica_torpedoVoz
              ? 1
              : 0
            : -1,
        notifica_WhatsApp:
          statusSwitch.notifica_WhatsApp >= 0
            ? notifica_WhatsApp
              ? 1
              : 0
            : -1,
      })
      .then((response) => {
        const toastrInstance = toastr.success;
        const options = {
          timeOut: 1000 * 10,
          showCloseButton: true,
          progressBar: true,
          position: "top-right",
        };
        toastrInstance("", "Dados alterados com sucesso!.", options);
        history.push("/");
      })
      .catch((err) => {
        const toastrInstance = toastr.error;
        const options = {
          timeOut: 1000 * 10,
          showCloseButton: true,
          progressBar: true,
          position: "top-right",
        };
        toastrInstance(
          "",
          "Todos os campos devem ser preenchido corretamente!",
          options
        );
      });
  };

  const notificaAPP = () => {
    setApp(!app);
  };

  const notificaWeb = () => {
    setWeb(!web);
  };

  const notificaWhatsapp = () => {
    setWhatsapp(!whatsapp);
  };

  const notificaSms = () => {
    if (voz) {
      setVoz(false);
      setSms(true);
    } else {
      setSms(!sms);
    }
  };

  const notificaVoz = () => {
    if (sms) {
      setVoz(true);
      setSms(false);
    } else {
      setVoz(!voz);
    }
  };

  useEffect(() => {
    setValue("notifica_APP", app);
  }, [app, setValue]);

  useEffect(() => {
    setValue("notifica_Web", web);
  }, [web, setValue]);

  useEffect(() => {
    setValue("notifica_WhatsApp", whatsapp);
  }, [whatsapp, setValue]);

  useEffect(() => {
    setValue("notifica_SMS", sms);
  }, [sms, setValue]);

  useEffect(() => {
    setValue("notifica_torpedoVoz", voz);
  }, [voz, setValue]);

  const overview = useCallback(() => {
    history.push("/overview");
  }, [history]);

  return (
    <>
      {loading ? (
        <div>
          <Container fluid>
            <Row>
              <div className="contentHeader">
                <h5>MEUS DADOS</h5>
              </div>
            </Row>
            <hr
              style={{
                backgroundColor: "GrayText",
                width: "100%",
              }}
            />
          </Container>
          <Container>
            <Row>
              <Col sm="2"></Col>
              <Col sm="8">
                <Row>
                  <Card>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div>
                        <br />
                        <FloatingLabel
                          controlId="name"
                          label="Como deseja ser identificado:"
                        >
                          <Form.Control
                            type="text"
                            placeholder="nome"
                            defaultValue={user.name}
                            {...register("name")}
                          />
                        </FloatingLabel>
                        <br />

                        <FloatingLabel
                          controlId="email"
                          label="Email:"
                        >
                          <Form.Control
                            type="email"
                            placeholder="nome@example.com"
                            defaultValue={user.email}
                            {...register("email")}
                          />
                        </FloatingLabel>

                        <br />

                        <InputMask
                          defaultValue={user.cell}
                          {...register("cell")}
                          mask="(99) 99999-9999"
                          required
                        >
                          {(inputProps: any) => (
                            <FloatingLabel
                              controlId="floatingInputGrid"
                              label="Celular:"
                            >
                              <Form.Control
                                minLength={11}
                                name="cell"
                                type="text"
                              />
                            </FloatingLabel>
                          )}
                        </InputMask>

                        <br />
                      </div>
                      <br />
                      <div style={{ marginLeft: "1rem" }}>
                        <p>Como deseja ser notificado:</p>

                        <CanView
                          isView={statusSwitch.notifica_APP}
                          id="notifica_APP"
                          label="App"
                          name="notifica_APP"
                        >
                          <FormGroup switch>
                            <Input
                              type="switch"
                              id="notifica_APP"
                              label="App"
                              name="notifica_APP"
                              checked={app}
                              onChange={() => notificaAPP()}
                              inputRef={register}
                              defaultChecked
                            />
                            <Label>APP</Label>
                          </FormGroup>
                        </CanView>

                        <CanView
                          isView={statusSwitch.notifica_Web}
                          id="notifica_Web"
                          label="Web"
                          name="notifica_Web"
                        >
                          <FormGroup switch>
                            <Input
                              type="switch"
                              id="notifica_Web"
                              label="Web"
                              name="notifica_Web"
                              checked={web}
                              onChange={() => notificaWeb()}
                              inputRef={register}
                              defaultChecked
                            />
                            <Label>WEB</Label>
                          </FormGroup>
                        </CanView>
                        <CanView
                          isView={statusSwitch.notifica_WhatsApp}
                          id="notifica_WhatsApp"
                          label="WhatsApp"
                          name="notifica_WhatsApp"
                        >
                          <FormGroup switch>
                            <Input
                              type="switch"
                              id="notifica_WhatsApp"
                              label="WhatsApp"
                              name="notifica_WhatsApp"
                              checked={whatsapp}
                              onChange={() => notificaWhatsapp()}
                              inputRef={register}
                            />
                            <Label>WhatsApp</Label>
                          </FormGroup>
                        </CanView>

                        <CanView
                          isView={statusSwitch.notifica_SMS}
                          id="notifica_SMS"
                          label="SMS"
                          name="notifica_SMS"
                        >
                          <FormGroup switch>
                            <Input
                              type="switch"
                              id="notifica_SMS"
                              name="notifica_SMS"
                              checked={sms}
                              onChange={() => notificaSms()}
                              inputRef={register}
                            />
                            <Label>SMS</Label>
                          </FormGroup>
                        </CanView>

                        <CanView
                          isView={statusSwitch.notifica_torpedoVoz}
                          id="notifica_torpedoVoz"
                          label="Mensagem de voz"
                          name="notifica_torpedoVoz"
                        >
                          <FormGroup switch>
                            <Input
                              type="switch"
                              id="notifica_torpedoVoz"
                              name="notifica_torpedoVoz"
                              checked={voz}
                              onChange={() => notificaVoz()}
                              inputRef={register}
                            />
                            <Label>Mensagem de voz</Label>
                          </FormGroup>
                        </CanView>
                      </div>
                      <br />
                      <div
                        style={{
                          display: "flex",

                          justifyContent: "end",
                        }}
                      >
                        <Button
                          name="cancelar"
                          color="secondary"
                          type="button"
                          className="mr-1 mb-1"
                          onClick={overview}
                        >
                          <X className="mr-2 mb-1" size={15} />
                          Cancelar
                        </Button>
                        <Button
                          name="enviar"
                          color="primary"
                          type="submit"
                          className="mr-1 mb-1"
                        >
                          <Save className="mr-2 mb-1" size={15} />
                          Atualizar
                        </Button>
                      </div>

                      <br />
                    </form>
                  </Card>
                </Row>
              </Col>
              <Col sm="2"></Col>
            </Row>
          </Container>

          <ScrollTop style={{ bottom: "80px" }} />
        </div>
      ) : (
        <Loading width={10} height={10} color="primary" />
      )}
    </>
  );
}
