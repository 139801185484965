import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { Bell, AlertCircle } from "react-feather";
import { Link, useParams } from "react-router-dom";
import { ModalJustificativaInterno } from "../ModalJustificativaInterno";

type justificativa = {
  id: string;
  justificavel: boolean;
  textoDaNotificacacao: string;
};
interface AlertasData {
  alertasArray: justificativa[];
}

export default function Notifications({ alertasArray }: AlertasData) {
  const { eStation }: { eStation: string } = useParams();

  return (
    <Card>
      <CardHeader>
        <h5 className=" text-muted">
          {" "}
          <Bell /> Histórico de Notificações
        </h5>
        <p className=" text-muted">(registradas pelo sistema)</p>
      </CardHeader>
      <CardBody>
        {alertasArray?.map((alerta, id) => (
          <p key={id} style={{ fontSize: 12 }}>
            <AlertCircle style={{ marginBottom: "5px", color: "#f0ad4e" }} />{" "}
            {alerta.textoDaNotificacacao}
            {alerta.justificavel && <ModalJustificativaInterno rash={alerta.id} />}
          </p>
        )) || [
          "Prezado(a) aguarde um momento até que todos os dados sejam preenchidos completamente",
        ]}

        <Link key={"asdasd"} to={`/notificationHistory/${eStation}`}>
          <p
            style={{
              width: "100%",
              textAlign: "right",
              fontSize: "12px",
            }}
          >
            [Ver mais]
          </p>
        </Link>
      </CardBody>
    </Card>
  );
}
