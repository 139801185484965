

import { Card, CardBody, CardHeader } from "reactstrap";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
interface PerfilEnergeticoData {
  energizado: number;
  desenergizado: number;
  atualizadoEm?: string;
}
export default function Pie({
  energizado,
  desenergizado,
  atualizadoEm,
}: PerfilEnergeticoData) {
  // const data = [energizado, desenergizado];

  const data = [
    { name: "Energizado", y: energizado },
    { name: "Desernegizado", y: desenergizado },
  ];

  const colors = ["#fd7e14", "#343a40"];

  // Configuração do gráfico de pizza
  const options = {
    chart: {
      type: "pie",
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "", // Legenda na parte inferior do gráfico
    },
    credits: {
      enabled: true,
      text: "appsupply",
      href: "https://painel.appsupply.ml/",
    },
    plotOptions: {
      pie: {
        colors: colors, // Definindo as cores personalizadas
        dataLabels: {
          enabled: true,
        },
        showInLegend: true
      },
    },
    series: [
      {
        name: "Horas",
        data: data,
      },
    ],
    legend: {
      enabled: true, // Habilita a exibição da legenda
      align: 'center',
      verticalAlign: 'bottom',
      layout: 'horizontal',
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500, // Largura máxima do contêiner onde o gráfico está sendo renderizado
          },
          // Configurações específicas para o gráfico quando a largura do contêiner é menor ou igual a 500 pixels
          chartOptions: {
            plotOptions: {
              pie: {
                dataLabels: {
                  distance: -30,
                },
              },
            },
          },
        },
      ],
    },
  };

  return (
    <Card style={{ width: "100%" }}>
      <CardHeader>
        <h5 className=" text-muted">Perfil de Funcionamento Energético</h5>
        <p className=" text-muted">(nas últimas 24h)</p>
      </CardHeader>
      <CardBody>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </CardBody>
    </Card>
  );
}
