import React, { InputHTMLAttributes } from "react";
import { useField } from "@unform/core";
import { useEffect } from "react";
import { useRef } from "react";

import { Alert } from "reactstrap";
import { Container } from "./styles";
interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
}

const Input: React.FC<InputProps> = ({ name, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);
  return (
    <>
      <Container>
        <input defaultValue={defaultValue} ref={inputRef} {...rest} />
      </Container>
      <br />
      {!!error && (
        <Alert color="warning" style={{ textAlign: "center" }}>
          <b>Atenção! </b>
          {error}
        </Alert>
      )}
    </>
  );
};

export default Input;
