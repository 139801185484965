import { ScrollTop } from "primereact/scrolltop";
import { Col, Container, Row } from "reactstrap";
import { FormJustificativa } from "../../components/FormJustificativa/FormJustificativa";
import "./styles.css";

export function Justificativa() {
  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="12" md={12}>
            <h5
              style={{
                padding: 0,
                margin: 0,
              }}
            >
              Justificativa
            </h5>
            <hr
              style={{
                backgroundColor: "GrayText",
                width: "100%",
              }}
            />
          </Col>
        </Row>

        <FormJustificativa isRedirect />
      </Container>
      <ScrollTop style={{ bottom: "80px" }} />
    </>
  );
}
