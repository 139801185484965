import React, { useRef, useState, useEffect } from "react";
import ReactDatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useField } from "@unform/core";
import ptBR from "date-fns/locale/pt-BR";
registerLocale("ptBR", ptBR);

interface Props extends Omit<ReactDatePickerProps, "onChange"> {
  name: string;
  label?: string;
  selectDate?: string | Date;
}

export default function DatePicker({
  name,
  selectDate,
  label,
  ...rest
}: Props) {
  const datepickerRef = useRef(null);
  const { fieldName, registerField, defaultValue } = useField(name);

  const [date, setDate] = useState(defaultValue || selectDate || null);
  const dataAtual = new Date();

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      path: "props.selected",
      clearValue: (ref: any) => {
        ref.clear();
      },
    });
  }, [fieldName, registerField]);

  return (
    <div
      style={{
        marginRight: 5,
        marginBottom: "15px",
        display: "flex",
      }}
    >
      <label
        style={{
          textAlign: "left",
          fontSize: "15px",
          fontWeight: "bold",
          marginRight: 1,
          marginTop: 10,
        }}
      >
        {label}
      </label>
      <ReactDatePicker
        dateFormat="dd/MM/yyyy"
        maxDate={new Date()}
        minDate={new Date(dataAtual.setDate(dataAtual.getDate() - 62))}
        ref={datepickerRef}
        selected={date}
        locale="ptBR"
        onChange={setDate}
        {...rest}
        customInput={
          <input
            style={{ width: "7rem", borderRadius: 4, textAlign: "center" }}
          ></input>
        }
      />
    </div>
  );
}
