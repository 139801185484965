import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "reactstrap";
import Can from "../../components/Can/Can";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import api from "../../services/api";
import { ModalCreateUser } from "../../components/ModalCreateUser";
import { useHistory } from "react-router-dom";
import { ModalAdminUpdatedUser } from "../../components/ModalAdminUpdatedUser";

interface IUsers {
  id: number;
  name: string;
  email: string;
  cell: string;
  status: number;
}
export const Users = () => {
  const [users, setUsers] = useState<IUsers[]>([] as IUsers[]);
  const history = useHistory();
  useEffect(() => {
    api
      .get("/users/admin")
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        history.push("/500");
        api.post("/logs/frontEnd", {
          log: JSON.stringify(error),
        });
      });
  }, [history]);

  const [search, setSearch] = useState("");
  const _filter =
    search.length > 2
      ? users.filter(
          (user) =>
            user.name.toUpperCase().includes(search.toUpperCase()) ||
            user.cell.toUpperCase().includes(search.toUpperCase()) ||
            user.email.toUpperCase().includes(search.toUpperCase())
        )
      : [];
  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearch(e.target.value)
            }
            id="Busca"
            name="Busca"
            placeholder="Busca"
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();

  const actionBodyTemplate = (rowData: IUsers) => {
    return (
      <Col className="d-flex">
        <ModalAdminUpdatedUser userId={rowData.id} />
      </Col>
    );
  };

  return (
    <Can roles="ROLE_USER">
      <Container fluid>
        <Row>
          <Col lg="12" md={12}>
            <h5
              style={{
                padding: 0,
                margin: 0,
              }}
            >
              Listagem de Usuários
            </h5>
            <hr
              style={{
                backgroundColor: "GrayText",
                width: "100%",
              }}
            />
          </Col>
        </Row>

        <ModalCreateUser />
        <Card>
          <DataTable
            responsiveLayout="scroll"
            value={search.length > 2 ? _filter : users}
            paginator
            rows={5}
            tableStyle={{ minWidth: "20rem" }}
            emptyMessage="Não há dados a serem amostrados."
            globalFilterFields={["name", "cell"]}
            header={header}
          >
            <Column
              field="name"
              header="Nome"
              style={{ width: "50%", fontSize: ".8rem" }}
            ></Column>

            <Column
              field="cell"
              header="Telefone"
              style={{ width: "50%", fontSize: ".8rem" }}
            ></Column>

            <Column
              body={actionBodyTemplate}
              header="Action"
              style={{ width: "10%", fontSize: ".8rem" }}
            ></Column>
          </DataTable>
        </Card>
      </Container>
    </Can>
  );
};
