import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import { ScrollTop } from "primereact/scrolltop";

import { useHistory, useParams } from "react-router-dom";
import { AxiosError } from "axios";
import BarChart from "../../components/charts/Bar";
import api from "../../services/api";
import Loading from "../../components/Loading";
import PieChart from "../../components/charts/Pie";
import "./styles.css";
import { Bell, Clock } from "react-feather";
type justificativa = {
  id: string;
  justificavel: boolean;
  textoDaNotificacacao: string;
};
interface AlertasData {
  qtdAlertasHoje: string;
  qtdAlertasOntem: string;
  qtdAlertasMes: string;
  atualizadoEm: string;
  alertas: string[];
  alertasArray: justificativa[];
}
interface HistoricoEnergeticoData {
  VerMais: string;
  time_arr: string[];
  energizado_arr: number[];
  desenergizado_arr: number[];
  atualizadoEm: string;
}
interface PerfilEnergeticoData {
  energizado: number;
  desenergizado: number;
  atualizadoEm: string;
}
interface EquipmentData {
  nome: string;
  idSisweb: number;
}

interface StatisticData {
  dadosEquipamento: EquipmentData;
  HistoricoDeAlertas: AlertasData;
  Historico_Energetico: HistoricoEnergeticoData;
  Perfil_Energetico: PerfilEnergeticoData;
  PontosDeMedicao: [
    {
      AtualizadoEm: string;
      LabelDaMedicao: string;
      LocalDaMedicao: string;
      MaximoHistorico: number;
      MinimoHistorico: number;
      MedicaoForaDeConformidade: number;
      PontoMedicaoId: number;
      TipoMedicao: number;
      UltimaMedicao: number;
      VerMais: string;
    }
  ];
}

export function AnaliseConsolidada() {
  const { eStation, station }: { eStation: string; station: string } =
    useParams();

  const [statistic, setStatistic] = useState({} as StatisticData);
  const [loading, setLoading] = useState(false);
  const [, setInitial] = useState(true);
  const [countError, setCountError] = useState(0);

  const history = useHistory();
  useEffect(() => {
    api
      .get(`/dashboard/${station.toLowerCase()}/${eStation}`)
      .then((response) => {
        setStatistic(response.data);
        setInitial(false);
      })
      .catch((error: AxiosError) => {
        if (countError === 3) {
          api.post("/logs/frontEnd", {
            log: JSON.stringify(error.stack),
          });
          setCountError(0);
          history.push("/500");
        }

        setCountError(countError + 1);
      })
      .finally(() => {
        setLoading(true);
      });
    return () => {};
  }, [eStation, station, history, countError]);


  return (
    <>
      {loading ? (
        <>
          {" "}
          <Container fluid>
            <Row>
              <Col lg="12" md={12}>
                <h5
                  style={{
                    padding: 0,
                    margin: 0,
                  }}
                >
                  Análise Consolidada
                </h5>
                <hr
                  style={{
                    backgroundColor: "GrayText",
                    width: "100%",
                  }}
                />
                <Row>
                  <Col lg="3">
                    <Card style={{ width: "100%" }}>
                      <CardBody className="py-3">
                        <Clock className="feather-lg " color={"blue"} />

                        <p className="mb-2 mt-1 ml-2">
                          <b>Up-time (funcionamento)</b>
                        </p>

                        <p className="mb-3  ml-2" style={{ fontSize: ".9rem" }}>
                          7h 44m 37s = 55.36%
                        </p>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col lg="3">
                    <Card style={{ width: "100%" }}>
                      <CardBody className="py-3">
                        <Clock className="feather-lg" color={"red"} />

                        <p className="mb-2 mt-1 ml-2">
                          <b>Down-time (desligado)</b>
                        </p>

                        <p className="mb-3  ml-2" style={{ fontSize: ".9rem" }}>
                          7h 44m 37s = 55.36%
                        </p>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col lg="3">
                    <Card style={{ width: "100%" }}>
                      <CardBody className="py-3">
                        <Clock className="feather-lg " color={"green"} />

                        <p className="mb-2 mt-1 ml-2">
                          <b> Eco-Power (economonia de energia)</b>
                        </p>

                        <p className="mb-3  ml-2" style={{ fontSize: ".9rem" }}>
                          7h 44m 37s = 55.36%
                        </p>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col lg="3">
                    <Card style={{ width: "100%" }}>
                      <CardBody className="py-3">
                        <Bell className="feather-lg " color={"blue"} />

                        <p className="mb-2 mt-1 ml-2">
                          <b>Quantidade de Eventos de Parada</b>
                        </p>

                        <p className="mb-3  ml-2" style={{ fontSize: ".9rem" }}>
                          9
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col lg="4" className="d-flex">
                    {statistic.Perfil_Energetico !== null ? (
                      <PieChart
                        energizado={statistic.Perfil_Energetico?.energizado}
                        desenergizado={
                          statistic.Perfil_Energetico?.desenergizado
                        }
                      />
                    ) : (
                      <></>
                    )}
                  </Col>

                  <Col lg="4" className="d-flex ">
                    <Card style={{ width: "100%" }}>
                      <CardHeader>
                        <h5>Resumo Histórico</h5>
                        <p className=" text-muted">(nos últimos dias)</p>
                      </CardHeader>
                      <CardBody>
                        <div className="containerTable">
                          <Table  responsive size="sm" >
                            <thead>
                              <th>Parada</th>
                              <th>Retorno</th>
                              <th>Duração</th>
                              <th>Classificação</th>
                            </thead>
                            <tbody>
                              <tr>
                                <td>10/07/23 17:14:34</td>
                                <td>10/07/23 17:55:31</td>
                                <td>0h 40m 57s</td>
                                <td>Possível EcoPower</td>
                              </tr>
                              <tr>
                                <td>10/07/23 17:14:34</td>
                                <td>10/07/23 17:55:31</td>
                                <td>0h 40m 57s</td>
                                <td>Possível EcoPower</td>
                              </tr>
                              <tr>
                                <td>10/07/23 17:14:34</td>
                                <td>10/07/23 17:55:31</td>
                                <td>0h 40m 57s</td>
                                <td>Possível EcoPower</td>
                              </tr>
                              <tr>
                                <td>10/07/23 17:14:34</td>
                                <td>10/07/23 17:55:31</td>
                                <td>0h 40m 57s</td>
                                <td>Possível EcoPower</td>
                              </tr>
                              <tr>
                                <td>10/07/23 17:14:34</td>
                                <td>10/07/23 17:55:31</td>
                                <td>0h 40m 57s</td>
                                <td>Possível EcoPower</td>
                              </tr>
                              <tr>
                                <td>10/07/23 17:14:34</td>
                                <td>10/07/23 17:55:31</td>
                                <td>0h 40m 57s</td>
                                <td>Possível EcoPower</td>
                              </tr>
                              <tr>
                                <td>10/07/23 17:14:34</td>
                                <td>10/07/23 17:55:31</td>
                                <td>0h 40m 57s</td>
                                <td>Possível EcoPower</td>
                              </tr>
                              <tr>
                                <td>10/07/23 17:14:34</td>
                                <td>10/07/23 17:55:31</td>
                                <td>0h 40m 57s</td>
                                <td>Possível EcoPower</td>
                              </tr>

                              <tr>
                                <td>10/07/23 17:14:34</td>
                                <td>10/07/23 17:55:31</td>
                                <td>0h 40m 57s</td>
                                <td>Possível EcoPower</td>
                              </tr>

                              <tr>
                                <td>10/07/23 17:14:34</td>
                                <td>10/07/23 17:55:31</td>
                                <td>0h 40m 57s</td>
                                <td>Possível EcoPower</td>
                              </tr>
                              <tr>
                                <td>10/07/23 17:14:34</td>
                                <td>10/07/23 17:55:31</td>
                                <td>0h 40m 57s</td>
                                <td>Possível EcoPower</td>
                              </tr>
                              <tr>
                                <td>10/07/23 17:14:34</td>
                                <td>10/07/23 17:55:31</td>
                                <td>0h 40m 57s</td>
                                <td>Possível EcoPower</td>
                              </tr>
                              <tr>
                                <td>10/07/23 17:14:34</td>
                                <td>10/07/23 17:55:31</td>
                                <td>0h 40m 57s</td>
                                <td>Possível EcoPower</td>
                              </tr>
                              <tr>
                                <td>10/07/23 17:14:34</td>
                                <td>10/07/23 17:55:31</td>
                                <td>0h 40m 57s</td>
                                <td>Possível EcoPower</td>
                              </tr>
                              <tr>
                                <td>10/07/23 17:14:34</td>
                                <td>10/07/23 17:55:31</td>
                                <td>0h 40m 57s</td>
                                <td>Possível EcoPower</td>
                              </tr>
                              <tr>
                                <td>10/07/23 17:14:34</td>
                                <td>10/07/23 17:55:31</td>
                                <td>0h 40m 57s</td>
                                <td>Possível EcoPower</td>
                              </tr>
                              <tr>
                                <td>10/07/23 17:14:34</td>
                                <td>10/07/23 17:55:31</td>
                                <td>0h 40m 57s</td>
                                <td>Possível EcoPower</td>
                              </tr>

                              <tr>
                                <td>10/07/23 17:14:34</td>
                                <td>10/07/23 17:55:31</td>
                                <td>0h 40m 57s</td>
                                <td>Possível EcoPower</td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="4" className="d-flex">
                    {statistic.Historico_Energetico !== null ? (
                      <BarChart
                        VerMais={statistic.Historico_Energetico.VerMais}
                        energizado_arr={
                          statistic.Historico_Energetico?.energizado_arr
                        }
                        desenergizado_arr={
                          statistic.Historico_Energetico?.desenergizado_arr
                        }
                        time_arr={statistic.Historico_Energetico?.time_arr}
                      />
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
          <ScrollTop style={{ bottom: "80px" }} />
        </>
      ) : (
        <Loading width={10} height={10} color="primary" />
      )}
    </>
  );
}
