import React from "react";

interface WrapperProps {
  isBoxed: boolean;
  children: object;
}
const Wrapper = ({ isBoxed, children }: WrapperProps) => (
  <div className={"wrapper " + (isBoxed ? "wrapper-boxed" : "")}>
    {children}
  </div>
);

export default Wrapper;
