import { useEffect, useState } from "react";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { Timeline } from "primereact/timeline";
import { Card } from "primereact/card";
import { Button, Input, InputGroup } from "reactstrap";
import { ScrollTop } from "primereact/scrolltop";

import api from "../../services/api";
import Loading from "../../components/Loading";
import { useHistory } from "react-router-dom";
import Alert from "../../components/Alert";
import {
  Check,
  AlertCircle,
  AlertTriangle,
  Sliders,
  Battery,
  BatteryCharging,
} from "react-feather";
import { ModalJustificativaInterno } from "../../components/ModalJustificativaInterno";

import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBatteryHalf,
  faLeaf,
  faPlug,
  faTemperatureHigh,
  faTemperatureLow,
} from "@fortawesome/free-solid-svg-icons";
import { AxiosError } from "axios";
interface itemProps {
  id: string;
  tipo: string;
  icone: string;
  titulo: string;
  justificavel: boolean;
  dataHora: string;
  color: string;
  corpoDaNotificacao: string;
  rotaDashboard: string;
  link: (rota: string) => void;
}

const CustomizedContent = (item: itemProps) => {
  const history = useHistory();
  return (
    <Card title={item.tipo} style={{ fontSize: 12 }} subTitle={item.dataHora}>
      <p style={{ fontSize: ".8rem", color: "#000" }}>
        {item.corpoDaNotificacao}
      </p>
      <br />
      <div style={{ display: "flex", justifyContent: "end", flexWrap: "wrap" }}>
        <Button
          color="secondary"
          className="mr-1 mb-1"
          onClick={() => history.push(`/dashboard/simea/${item.rotaDashboard}`)}
          outline
        >
          <Sliders size={15} /> Dashboard
        </Button>
        {item.justificavel && (
          <ModalJustificativaInterno isRedirect rash={item.id.toString()} />
        )}
      </div>
    </Card>
  );
};

const CustomizedMarker = (item: itemProps) => {
  return (
    <span
      className="custom-marker shadow-8"
      style={{
        width: "2rem",
        height: "2rem",
        borderRadius: 20,
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "white",
      }}
    >
      {/* <i className={item.icone}></i> */}
      {item.icone === "Check" ? (
        <Check size="20" color={"#4bbf73"} className="iconCheck" />
      ) : item.icone === "AlertTriangle" ? (
        <AlertTriangle
          size="20"
          color={"#d9534f"}
          className="iconAlertTriangle"
        />
      ) : item.icone === "AlertCircle" ? (
        <AlertCircle size="20" color={"#f0ad4e"} className="iconAlertCircle" />
      ) : item.icone === "faTemperatureHigh" ? (
        <FontAwesomeIcon
          color={"#d9534f"}
          icon={faTemperatureHigh}
          className="iconAlertTriangle"
        />
      ) : item.icone === "faTemperatureLow" ? (
        <FontAwesomeIcon
          color={"#f0120a"}
          icon={faTemperatureLow}
          className="iconAlertTriangle"
        />
      ) : item.icone === "Battery" ? (
        <Battery
          size="20"
          color={"#7c7c7c"}
          className={"iconBatteryCharging"}
        />
      ) : item.icone === "BatteryCharging" ? (
        <BatteryCharging
          size="20"
          color={"#0d1a26"}
          className={"iconBatteryCharging"}
        />
      ) : item.icone === "faBatteryHalf" ? (
        <FontAwesomeIcon
          color={"#66ff99"}
          icon={faBatteryHalf}
          className={"iconBatteryHalf"}
        />
      ) : item.icone === "faLeaf" ? (
        <FontAwesomeIcon color={"#00cc00"} icon={faLeaf} className={"faLeaf"} />
      ) : item.icone === "faPlug" ? (
        <FontAwesomeIcon color={"#e68a00"} icon={faPlug} className={"faPlug"} />
      ) : (
        <></>
      )}
    </span>
  );
};

export function TimeLine() {
  const [repos, setRepos] = useState<itemProps[]>([] as itemProps[]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const plataform = localStorage.getItem("@supply:plataform");

  useEffect(() => {
    setLoading(false);
    api
      .get(`/timeLine`)
      .then((response) => {
        setRepos(response.data);
      })
      .catch((error: AxiosError) => {
        history.push("/500");
        api.post("/logs/frontEnd", {
          log: JSON.stringify(error.stack),
          platform: plataform ? plataform : "web",
        });
      })
      .finally(() => {
        setLoading(true);
      });
  }, [history, plataform]);

  const filteredRepos =
    search.length > 2
      ? repos.filter((repo) =>
          repo.corpoDaNotificacao.toLowerCase().includes(search.toLowerCase())
        )
      : [];
  return loading ? (
    <>
      <div className="contentHeader">
        <h5>TIMELINE</h5>
        <InputGroup
          style={{
            width: "10rem",
          }}
        >
          <Input
            name="search"
            placeholder="Buscar"
            type="text"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearch(e.target.value)
            }
          />
          {/* <InputGroupAddon
            style={{
              height: "1.95rem",
            }}
            addonType="prepend"
          >
            &#128269;
          </InputGroupAddon> */}
        </InputGroup>
      </div>
      <hr
        style={{
          backgroundColor: "GrayText",
          width: "100%",
        }}
      />

      {search.length > 2 ? (
        <div className="timeline-demo">
          <Timeline
            value={filteredRepos}
            align="alternate"
            className="customized-timeline"
            marker={CustomizedMarker}
            content={CustomizedContent}
          />
        </div>
      ) : (
        <div className="timeline-demo">
          <Timeline
            value={repos}
            align="alternate"
            className="customized-timeline"
            marker={CustomizedMarker}
            content={CustomizedContent}
          />
        </div>
      )}
      {filteredRepos.length <= 0 && search.length > 2 && (
        <Alert
          BgColor={"#84aef2"}
          message={"Ops...busca sem resultados!"}
          icon={<AlertCircle size="25" />}
        />
      )}
      <ScrollTop style={{ bottom: "80px" }} />
    </>
  ) : (
    <Loading width={10} height={10} color="primary" />
  );
}
