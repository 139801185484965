import styled from 'styled-components'

export const Container = styled.div`
    display:flex;
    flex:1;
    justify-content:center;
    height: calc(2.2rem + 2px);
    border-radius: 0.4rem;

    input{
        display:flex;
        height: calc(2.2rem + 2px);
        width: 60%;
        padding: 0.35rem 1rem;
        font-size: 1rem;
        line-height: 1.5;
        border: solid 1px;
        border-radius: 0.4rem;
        font-size:20px;
        padding:0px;
        text-align:center;
    }

    input::placeholder{
        text-align:center
    }
`;