import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Card,
  CardBody,
  Row,
  Input,
} from "reactstrap";

import { InputMask } from "primereact/inputmask";
import Loading from "../Loading";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";

import { useForm } from "react-hook-form";
import api from "../../services/api";
import { AxiosError } from "axios";
import { toastr } from "react-redux-toastr";
type FormData = {
  name: string;
  email: string;
  cell: string;
  status: boolean;
  roleUser: boolean;
  roleAdmin: boolean;
  roleMaster: boolean;
  notifica_Web: boolean;
  notifica_APP: boolean;
  notifica_SMS: boolean;
  notifica_WhatsApp: boolean;
  notifica_torpedoVoz: boolean;
};

export const ModalCreateUser = () => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [app, setApp] = useState(false);
  const [web, setWeb] = useState(false);
  const [whatsapp, setWhatsapp] = useState(false);
  const [sms, setSms] = useState(false);
  const [voz, setVoz] = useState(false);

  const [status, setStatus] = useState(false);

  const toggle = () => setModal(!modal);
  const { register, handleSubmit, setValue } = useForm<FormData>({
    defaultValues: {
      status: false,
      notifica_APP: false,
      notifica_Web: false,
      notifica_SMS: false,
      notifica_torpedoVoz: false,
      notifica_WhatsApp: false,
    },
  });

  const statusUser = () => {
    setStatus(!status);
  };

  const notificaAPP = () => {
    setApp(!app);
  };

  const notificaWeb = () => {
    setWeb(!web);
  };

  const notificaWhatsapp = () => {
    setWhatsapp(!whatsapp);
  };

  const notificaSms = () => {
    if (voz) {
      setVoz(false);
      setSms(true);
    } else {
      setSms(!sms);
    }
  };

  const notificaVoz = () => {
    if (sms) {
      setVoz(true);
      setSms(false);
    } else {
      setVoz(!voz);
    }
  };

  useEffect(() => {
    setValue("status", status);
  }, [status, setValue]);

  useEffect(() => {
    setValue("notifica_APP", app);
  }, [app, setValue]);

  useEffect(() => {
    setValue("notifica_Web", web);
  }, [web, setValue]);

  useEffect(() => {
    setValue("notifica_WhatsApp", whatsapp);
  }, [whatsapp, setValue]);

  useEffect(() => {
    setValue("notifica_SMS", sms);
  }, [sms, setValue]);

  useEffect(() => {
    setValue("notifica_torpedoVoz", voz);
  }, [voz, setValue]);
  useEffect(() => {
    setLoading(true);
  }, []);

  const [selectedRoles, setSelectedRoles] = useState<number[]>([]);

  const onSubmit = ({
    name,
    email,
    cell,
    status,
    notifica_APP,
    notifica_Web,
    notifica_SMS,
    notifica_torpedoVoz,
    notifica_WhatsApp,
    roleAdmin,
    roleMaster,
    roleUser,
  }: FormData) => {
    let newRoles = [];

    if (roleAdmin) {
      newRoles.push(3);
    }

    if (roleUser) {
      newRoles.push(4);
    }
    if (roleMaster) {
      newRoles.push(5);
    }

    if (newRoles.length > 0) {
      api
        .post(`/users/admin`, {
          name,
          email,
          cell: cell.replace(/[^0-9]+/g, ""),
          roles: newRoles,
          status: status ? 1 : 0,
          notifica_APP: notifica_APP ? 1 : 0,
          notifica_Web: notifica_Web ? 1 : 0,
          notifica_SMS: notifica_SMS ? 1 : 0,
          notifica_torpedoVoz: notifica_torpedoVoz ? 1 : 0,
          notifica_WhatsApp: notifica_WhatsApp ? 1 : 0,
        })
        .then(() => {
          const toastrInstance = toastr.success;
          const options = {
            timeOut: 1000 * 10,
            showCloseButton: true,
            progressBar: true,
            position: "top-right",
          };
          toastrInstance("", "Usuário cadastrado com sucesso!.", options);
        })
        .catch((error: AxiosError) => {
          const toastrInstance = toastr.error;
          const options = {
            timeOut: 1000 * 10,
            showCloseButton: true,
            progressBar: true,
            position: "top-right",
          };
          toastrInstance(
            "",
            "Todos os campos devem ser preenchido corretamente!",
            options
          );
          api.post("/logs/frontEnd", {
            log: JSON.stringify(error.stack),
          });
        });
    } else {
      api
        .post(`/users/admin`, {
          name,
          email,
          cell: cell.replace(/[^0-9]+/g, ""),
          status: status ? 1 : 0,
          notifica_APP: notifica_APP ? 1 : 0,
          notifica_Web: notifica_Web ? 1 : 0,
          notifica_SMS: notifica_SMS ? 1 : 0,
          notifica_torpedoVoz: notifica_torpedoVoz ? 1 : 0,
          notifica_WhatsApp: notifica_WhatsApp ? 1 : 0,
        })
        .then(() => {
          const toastrInstance = toastr.success;
          const options = {
            timeOut: 1000 * 10,
            showCloseButton: true,
            progressBar: true,
            position: "top-right",
          };
          toastrInstance("", "Usuário cadastrado com sucesso!.", options);
        })
        .catch((error: AxiosError) => {
          console.log("Erro ao cadastrar usuario");
          const toastrInstance = toastr.error;
          const options = {
            timeOut: 1000 * 10,
            showCloseButton: true,
            progressBar: true,
            position: "top-right",
          };
          toastrInstance(
            "",
            "Todos os campos devem ser preenchido corretamente!",
            options
          );
          api.post("/logs/frontEnd", {
            log: JSON.stringify(error.stack),
          });
        });
    }
  };

  const handleCheckboxChange = (roleValue: number, isChecked: boolean) => {
    if (isChecked) {
      setSelectedRoles((prevRoles) => [...prevRoles, roleValue]);
    } else {
      setSelectedRoles((prevRoles) =>
        prevRoles.filter((role) => role !== roleValue)
      );
    }
  };
  return loading ? (
    <>
      <Button
        onClick={toggle}
        color="primary"
        size="md"
        className="mr-1 mb-1"
        outline
      >
        Adicionar Usuário +
      </Button>

      <Modal isOpen={modal} toggle={toggle} size="md">
        <ModalHeader toggle={toggle}>Cadastro de Usuário</ModalHeader>
        <ModalBody>
          <Row sm={12} style={{ justifyContent: "center" }}>
            <Card style={{ width: "50rem" }}>
              <CardBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="flex-auto">
                    <label htmlFor="name" className="font-bold block mb-2">
                      Nome:
                    </label>
                    <InputText
                      className="w-100"
                      id="name"
                      placeholder="Nome"
                      required
                      {...register("name")} // Use o register do react-hook-form aqui
                    />
                  </div>

                  <div className="flex-auto">
                    <label htmlFor="email" className="font-bold block mb-2">
                      E-mail:
                    </label>
                    <InputText
                      className="w-100"
                      id="email"
                      type="email"
                      placeholder="E-mail"
                      {...register("email")} // Use o register do react-hook-form aqui
                    />
                  </div>

                  <div className="flex-auto">
                    <label htmlFor="cell" className="font-bold block mb-2">
                      Telefone:
                    </label>
                    <InputMask
                      className="w-100"
                      id="cell"
                      mask="(99) 99999-9999"
                      placeholder="(99) 99999-9999"
                      required
                      {...register("cell")} // Use o register do react-hook-form aqui
                    />
                  </div>

                  <div className="flex-auto mb-2">
                    <label htmlFor="status" className="font-bold block mb-2">
                      Status:
                    </label>

                    <Input
                      type="switch"
                      id="status"
                      label="App"
                      name="status"
                      checked={status}
                      onChange={() => statusUser()}
                      inputRef={register}
                      defaultChecked
                    />
                  </div>

                  {/* Restante do código... */}
                  <label className="font-bold block mb-2">
                    Roles do Usuário:
                  </label>
                  <div className=" field-checkbox">
                    <Checkbox
                      inputId="role_user"
                      value={3}
                      checked={selectedRoles.includes(3)}
                      onChange={(e) => {
                        setValue("roleUser", e.checked);
                        handleCheckboxChange(3, e.checked);
                      }}
                    />
                    <label htmlFor="role_user">Usuário</label>
                  </div>
                  <div className=" field-checkbox">
                    <Checkbox
                      inputId="role_Admin"
                      value={4}
                      checked={selectedRoles.includes(4)}
                      onChange={(e) => {
                        setValue("roleAdmin", e.checked);
                        handleCheckboxChange(4, e.checked);
                      }}
                    />
                    <label htmlFor="role_Admin">Administrador</label>
                  </div>
                  <div className=" field-checkbox">
                    <Checkbox
                      inputId="role_master"
                      value={5}
                      checked={selectedRoles.includes(5)}
                      onChange={(e) => {
                        setValue("roleMaster", e.checked);
                        handleCheckboxChange(5, e.checked);
                      }}
                    />
                    <label htmlFor="role_master">Master</label>
                  </div>
                  <label
                    htmlFor="notification"
                    className="font-bold block mb-2"
                  >
                    Notificações
                  </label>

                  <Input
                    type="switch"
                    id="notifica_APP"
                    label="App"
                    name="notifica_APP"
                    checked={app}
                    onChange={() => notificaAPP()}
                    inputRef={register}
                    defaultChecked
                  />

                  <Input
                    type="switch"
                    id="notifica_Web"
                    label="Web"
                    name="notifica_Web"
                    checked={web}
                    onChange={() => notificaWeb()}
                    inputRef={register}
                    defaultChecked
                  />

                  <Input
                    type="switch"
                    id="notifica_WhatsApp"
                    label="WhatsApp"
                    name="notifica_WhatsApp"
                    checked={whatsapp}
                    onChange={() => notificaWhatsapp()}
                    inputRef={register}
                  />

                  <Input
                    type="switch"
                    id="notifica_SMS"
                    label="SMS"
                    name="notifica_SMS"
                    checked={sms}
                    onChange={() => notificaSms()}
                    inputRef={register}
                  />

                  <Input
                    type="switch"
                    id="notifica_torpedoVoz"
                    label="Mensagem de voz"
                    name="notifica_torpedoVoz"
                    checked={voz}
                    onChange={() => notificaVoz()}
                    inputRef={register}
                  />

                  <div className="d-flex mt-4 justify-content-end">
                    <Button
                      type="submit"
                      color="primary"
                      size="md"
                      className="mr-1 mb-1"
                      outline
                    >
                      Salvar
                    </Button>
                    <Button
                      color="secondary"
                      size="md"
                      className="mr-1 mb-1"
                      outline
                    >
                      Cancelar
                    </Button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </Row>
        </ModalBody>
      </Modal>
    </>
  ) : (
    <Loading width={10} height={10} color="primary" />
  );
};
