import React, { useEffect, useRef, useState } from "react";

import api from "../../../services/api";
import Loading from "../../Loading";
import { useHistory, useParams } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
require("highcharts/modules/exporting")(Highcharts);

interface DataHistory {
  dataHistory: {
    nome_ambiente: string;
    descricao_medicao: string;
    tipo: number;
    periodoInicio: string;
    periodoFim: string;
    presetMax: number;
    presetMin: number;
    maximoHistorico: number;
    minimoHistorico: number;
    serie_A: number[];
    serie_B: number[];
    serie_C: number[];
    datasEpoch: string[];
  };
}

interface FaseProps {
  props?: HighchartsReact.Props;
  dtfim?: string;
  dtini?: string;
}

export const Fase = ({ dtfim, dtini, props }: FaseProps) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const [dataApi, setDataApi] = useState<DataHistory>({} as DataHistory);
  const [loading, setLoading] = useState(false);
  const [maximoHistorico, setMaximoHistorico] = useState(0);
  const [minimoHistorico, setMinimoHistorico] = useState(0);

  const token = localStorage.getItem("@supply:token");
  const device_id = localStorage.getItem("@supply:device_id");
  const plataform = localStorage.getItem("@supply:plataform");
  const { typeMeasurement }: { typeMeasurement: string } = useParams();
  const history = useHistory();
  useEffect(() => {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    api
      .post<DataHistory>(`/dataHistory/${typeMeasurement}`, {
        dataInicial: dtini,
        dataFinal: dtfim,
      })
      .then((response) => {
        setDataApi(response.data);
        if (
          response.data?.dataHistory.maximoHistorico >
          response.data?.dataHistory.presetMax
        ) {
          setMaximoHistorico(response.data?.dataHistory.maximoHistorico * 1.2);
        } else {
          setMaximoHistorico(response.data?.dataHistory.presetMax * 1.2);
        }

        if (
          response.data?.dataHistory.minimoHistorico <
          response.data?.dataHistory.presetMin
        ) {
          setMinimoHistorico(response.data?.dataHistory.minimoHistorico * 0.8);
        } else {
          setMinimoHistorico(response.data?.dataHistory.presetMin * 0.8);
        }
      })
      .catch((error) => {
        history.push("/500");
        api.post("/logs/frontEnd", {
          log: JSON.stringify(error.stack),
          platform: plataform ? plataform : "web",
        });
      })
      .finally(() => setLoading(true));
  }, [typeMeasurement, token, history, dtfim, dtini, plataform]);

  const options: Highcharts.Options = {
    title: {
      text: dataApi.dataHistory?.nome_ambiente,
    },
    subtitle: {
      text: dataApi.dataHistory?.descricao_medicao,
    },
    credits: {
      enabled: true,
      text: "appsupply",
      href: "https://painel.appsupply.ml/",
    },
    navigation: {
      buttonOptions: {
        enabled: true,
      },
    },
    rangeSelector: {
      buttons: [
        {
          type: "hour",
          count: 24,
          text: "24h",
        },
        {
          type: "day",
          count: 30,
          text: "30d",
        },
        {
          type: "day",
          count: 60,
          text: "60d",
        },

        {
          type: "all",
          text: "All",
        },
      ],
      selected: 3,
    },
    series: [
      {
        name: "Fase R",
        type: "line",
        data: dataApi.dataHistory?.serie_A,
        tooltip: {
          valueDecimals: 2,
          valueSuffix: "A",
        },
      },

      {
        name: "Fase S",
        type: "line",
        data: dataApi.dataHistory?.serie_B,
        tooltip: {
          valueDecimals: 2,
          valueSuffix: "A",
        },
      },

      {
        name: "Fase T",
        type: "line",
        data: dataApi.dataHistory?.serie_C,
        tooltip: {
          valueDecimals: 2,
          valueSuffix: "A",
        },
      },
    ],
    legend: {
      enabled: true,
    },
    yAxis: {
      tickInterval: Math.round(maximoHistorico) >= 60 ? 10 : 5,
      title: {
        text: "Fase (A)",
        align: "low",
      },
      opposite: false,
      max: Math.round(maximoHistorico),
      min: Math.round(minimoHistorico),

      plotLines: [
        {
          value: dataApi.dataHistory?.presetMax,
          color: "red",
          dashStyle: "ShortDash",
          width: 2,
          label: {
            text: `máx ${dataApi.dataHistory?.presetMax}`,
          },
        },
        {
          value: dataApi.dataHistory?.presetMin,
          color: "yellow",
          dashStyle: "ShortDash",
          width: 2,
          label: {
            text: `min ${dataApi.dataHistory?.presetMin}`,
          },
        },
      ],
    },
  };

  const optionsMobile: Highcharts.Options = {
    title: {
      text: dataApi.dataHistory?.nome_ambiente,
    },
    subtitle: {
      text: dataApi.dataHistory?.descricao_medicao,
    },
    credits: {
      enabled: true,
      text: "appsupply",
      href: "https://painel.appsupply.ml/",
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    rangeSelector: {
      buttons: [
        {
          type: "hour",
          count: 24,
          text: "24h",
        },
        {
          type: "day",
          count: 30,
          text: "30d",
        },
        {
          type: "day",
          count: 60,
          text: "60d",
        },

        {
          type: "all",
          text: "All",
        },
      ],
      selected: 3,
    },
    series: [
      {
        name: "Fase R",
        type: "line",
        data: dataApi.dataHistory?.serie_A,
        tooltip: {
          valueDecimals: 2,
          valueSuffix: "A",
        },
      },

      {
        name: "Fase S",
        type: "line",
        data: dataApi.dataHistory?.serie_B,
        tooltip: {
          valueDecimals: 2,
          valueSuffix: "A",
        },
      },

      {
        name: "Fase T",
        type: "line",
        data: dataApi.dataHistory?.serie_C,
        tooltip: {
          valueDecimals: 2,
          valueSuffix: "A",
        },
      },
    ],
    legend: {
      enabled: true,
    },
    yAxis: {
      tickInterval: Math.round(maximoHistorico) >= 60 ? 10 : 5,
      title: {
        text: "Fase (A)",
        align: "low",
      },
      opposite: false,
      max: Math.round(maximoHistorico),
      min: Math.round(minimoHistorico),

      plotLines: [
        {
          value: dataApi.dataHistory?.presetMax,
          color: "red",
          dashStyle: "ShortDash",
          width: 2,
          label: {
            text: `máx ${dataApi.dataHistory?.presetMax}`,
          },
        },
        {
          value: dataApi.dataHistory?.presetMin,
          color: "yellow",
          dashStyle: "ShortDash",
          width: 2,
          label: {
            text: `min ${dataApi.dataHistory?.presetMin}`,
          },
        },
      ],
    },
  };
  return loading ? (
    <>
      <Card style={{ width: "100%" }}>
        <CardBody>
          <div className="chart">
            <HighchartsReact
              highcharts={Highcharts}
              constructorType={"stockChart"}
              options={
                plataform === null && device_id === null
                  ? options
                  : optionsMobile
              }
              ref={chartComponentRef}
              {...props}
            />
          </div>
        </CardBody>
      </Card>
    </>
  ) : (
    <Loading width={10} height={10} color="primary" />
  );
};
