import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const PrivateRoutesToken: React.FC<RouteProps> = ({...rest }) => {
  const { user } = useAuth();

  if (!user()) {
    return <Redirect to="/" />;
  }

  return <Route {...rest} />;
};

export default PrivateRoutesToken;
