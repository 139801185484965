import { ReactNode } from "react";
import { FormGroup, Input, Label } from "reactstrap";


interface ICanViewProps {
  children: ReactNode;
  isView: number;
  label: string;
  name: string;
  id: string;
}
export default function CanView({
  isView,
  children,
  label,
  name,
  id,
}: ICanViewProps) {
  return isView >= 0 ? (
    <>{children}</>
  ) : (
    <FormGroup switch>
      <Input type="switch" id={id} name={name} checked={false} defaultChecked />
      <Label>{label}</Label>
    </FormGroup>
  );
}
