import {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useState,
  useCallback,
} from "react";
import { toastr } from "react-redux-toastr";
import { Howl } from "howler";
import api from "../services/api";
import { AxiosError } from "axios";
// import { useSelector } from "react-redux";

interface ToastContextState {}

interface AuthProviderProps {
  children?: ReactNode;
}

interface Notification {
  AlertasANotificar: {
    notifica_web: number;
    notificar: [
      {
        id: number;
        tipo: number;
        alerta: string;
      }
    ];
  };
}

// interface IState {
//   notificaWeb: boolean;
// }
const ToastContext = createContext<ToastContextState>({} as ToastContextState);

const ToastProvider = ({ children }: AuthProviderProps) => {
  const [notificar, setNotification] = useState<Notification>();
  const token = localStorage.getItem("@supply:token");
  const device_id = localStorage.getItem("@supply:device_id") || null;
  const plataform = localStorage.getItem("@supply:plataform") || null;

  const [loading, setLoading] = useState(false);

  const getNotification = useCallback(() => {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    api
      .get("/pushNotifications")
      .then((response) => {
        setNotification(response.data);

        if (response.data.notifica_Web > 0) {
          localStorage.setItem("@supply:isNotificaWeb", "true");
        } else {
          localStorage.setItem("@supply:isNotificaWeb", "false");
        }
      })
      .catch((error: AxiosError) => {
        api.post("/logs/frontEnd", {
          log: JSON.stringify(error.stack),
        });
        getNotification();
      })
      .finally(() => {
        setLoading(true);
      });
  }, [token]);

  useEffect(() => {
    const isNotificaWeb =
      localStorage.getItem("@supply:isNotificaWeb") === "false" ? false : true;

    if (isNotificaWeb) {
      if (device_id === null && plataform === null) {
        const interval = setInterval(async () => {
          getNotification();
        }, 5 * 1000);
        return () => clearInterval(interval);
      }
    }
  }, [getNotification, device_id, plataform, loading]);

  if (notificar === null || notificar === undefined) {
    getNotification();
  } else {
    if (
      notificar.AlertasANotificar === null ||
      notificar.AlertasANotificar === undefined
    ) {
      getNotification();
    } else {
      if (
        notificar.AlertasANotificar.notificar === null ||
        notificar.AlertasANotificar.notificar === undefined
      ) {
        getNotification();
      }
    }
  }
  useEffect(() => {
    if (device_id === null && plataform === null) {
      notificar?.AlertasANotificar.notificar.map((notification) => {
        const showToastr = () => {
          const options = {
            timeOut: 10000 * notificar.AlertasANotificar.notificar.length,
            showCloseButton: true,
            progressBar: true,
            position: "top-right",
          };
          const soundnotificationexecute = new Howl({
            src: ["./alerta.mp3"],
            volume: 0.6,
            autoplay: true,
          });
          const toastrInstance =
            notification.tipo === 0
              ? toastr.info
              : notification.tipo === 1
              ? toastr.warning
              : notification.tipo === 2
              ? toastr.error
              : toastr.success;
          toastrInstance("", notification.alerta, options);

          const time = setTimeout(() => {
            soundnotificationexecute.play();
          }, 5 * 1000);

          clearTimeout(time);
        };
        return showToastr();
      });
    }
  }, [notificar, device_id, plataform]);

  return <ToastContext.Provider value={{}}>{children}</ToastContext.Provider>;
};

function useToast(): ToastContextState {
  const context = useContext(ToastContext);
  return context;
}

export { ToastProvider, useToast };
