import { Button, Col, Row } from "reactstrap";
import InternalError from "../../assets/500.svg";
import { Link } from "react-router-dom";
export const InternalErrorServer = () => {
  return (
    <Col className="d-flex justify-content-center align-items-center h-100">
      <Row>
        <img width="70%" src={InternalError} alt="Card" />
        <Button color="primary">
          <Link to="/" className="sidebar-link">
            <h4>Tentar novamente</h4>
          </Link>
        </Button>
      </Row>
    </Col>
  );
};
