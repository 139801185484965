import { Alert } from "reactstrap";

interface PropsAlert {
  BgColor: string;
  message: string;
  icon: JSX.Element;
}
export default function Alerta({ BgColor, message, icon }: PropsAlert) {
  return (
    <Alert
      style={{
        backgroundColor: BgColor,
        color: "white",
        paddingTop: 5,
        paddingLeft: 12,
        paddingRight: 12,
        paddingBottom: 12,
      }}
      className="primary alert"
    >
      <div
        className="alert-message"
        style={{
          margin: 0,
          paddingTop: 5,
          paddingLeft: 0,
          paddingRight: 0,
          paddingBottom: 0,
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p
            style={{
              textAlign: "justify",
              fontSize: 12,
              fontWeight: "bold",
              margin: 0,
              padding: 0,
            }}
          >
            {icon} {message}
          </p>
        </div>
      </div>
    </Alert>
  );
}
