import { useEffect, useRef, useState } from "react";
import {
  Col,
  Card,
  CardBody,
  Media,
  Row,
  Container,
  Button,
  Input,
} from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { AlertCircle, Bell, RefreshCw } from "react-feather";
import { faCheck, faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { HistoryNotifications } from "../../components/charts/HistoryNotifications";

import api from "../../services/api";
import { AiOutlineMessage, AiOutlineWhatsApp } from "react-icons/ai";
import DatePicker from "../../components/DataPicker";

import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import Loading from "../../components/Loading";

import "./styles.css";
import { ModalJustificativaInterno } from "../../components/ModalJustificativaInterno";
import { ScrollTop } from "primereact/scrolltop";
import { AxiosError } from "axios";

interface IGraphicNotificationHistory {
  estacao: string;
  cards_NotifyStatistics: {
    hoje: number;
    ontem: number;
    ultimos7Dias: number;
    ultimos30Dias: number;
  };
  graph_NotifyStatistics: {
    timeStamp_Array: string[];
    whatsApp_Array: number[];
    sms_Array: number[];
    app_Array: number[];
  };
  periodoFim: string;
  periodoInicio: string;
}

interface IAccordionNotificationHistory {
  indicePagina: number;
  qtdPorPagina: number;
  totalDeNotificacoes: number;
  totalDePaginas: number;
  historicoNotificacoes: [
    {
      id: number;
      date_Hour: string;
      notification: string;
      justificativa: string;
      justificavel: boolean;
      whatsappEnviadoEm: string;
      whatsappRecebidoEm: string;
      whatsappLidoEm: string;
      smsEnviadoEm: string;
    }
  ];
}

interface PropsDates {
  dtfim: string;
  dtini: string;
}
export const DataNotification = () => {
  const { eStation }: { eStation: string } = useParams();
  const color = ["orange", "red", "green", "blue"];
  const [dataInit, setDataInit] = useState("");
  const [dataFina, setDataFina] = useState("");
  const [ordem, setOrdem] = useState("desc");

  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [pag, setPag] = useState(1);
  const history = useHistory();
  const [graphicNotification, setGraphicNotification] = useState(
    {} as IGraphicNotificationHistory
  );
  const [accordionNotification, setAccordionNotification] = useState(
    {} as IAccordionNotificationHistory
  );
  const [alertas, setAlertas] = useState([
    {
      quantidade: 0,
      periodo: "",
    },
  ]);

  function handleNextPag() {
    if (pag < accordionNotification.qtdPorPagina) {
      setPag(pag + 1);
    }
  }

  function hanslePrevious() {
    if (pag > 1) {
      setPag(pag - 1);
    }
  }

  function handleSelecOrder() {
    if (ordem === "asc") setOrdem("desc");
    else setOrdem("asc");
  }

  useEffect(() => {
    api.post(`/logs`, {
      url: `/notificationHistory/${eStation}`,
    });
  }, [eStation]);

  useEffect(() => {
    api
      .post<IGraphicNotificationHistory>(`/notificationsHistory/${eStation}`)
      .then((response) => {
        setGraphicNotification(response.data);
        setAlertas([
          {
            periodo: "Hoje",
            quantidade: response.data.cards_NotifyStatistics.hoje,
          },
          {
            periodo: "Ontem",
            quantidade: response.data.cards_NotifyStatistics.ontem,
          },
          {
            periodo: "Nos Últimos 7 dias",
            quantidade: response.data.cards_NotifyStatistics.ultimos7Dias,
          },
          {
            periodo: "Nos Últimos 30 dias",
            quantidade: response.data.cards_NotifyStatistics.ultimos30Dias,
          },
        ]);
        setDataInit(response.data.periodoInicio);
        setDataFina(response.data.periodoFim);
      })
      .catch((error: AxiosError) => {
        api.post("/logs/frontEnd", {
          log: JSON.stringify(error.stack),
        });
      })
      .finally(() => setLoading1(true));
  }, [eStation, history]);

  useEffect(() => {
    setLoading2(false);
    api
      .post(`/notificationsHistory/table/${eStation}/${pag}/${ordem}`, {})
      .then((response) => {
        setAccordionNotification(response.data);
      })
      .catch((error: AxiosError) => {
        api.post("/logs/frontEnd", {
          log: JSON.stringify(error.stack),
        });
      })
      .finally(() => setLoading2(true));
  }, [dataInit, dataFina, eStation, ordem, history, pag]);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = ({ dtfim, dtini }: PropsDates) => {
    setGraphicNotification({} as IGraphicNotificationHistory);
    setLoading1(false);
    setLoading2(false);

    api
      .post<IGraphicNotificationHistory>(`/notificationsHistory/${eStation}`, {
        dataInicial: dtini,
        dataFinal: dtfim,
      })
      .then((response) => {
        setGraphicNotification(response.data);
        setDataInit(response.data?.periodoInicio);
        setDataFina(response.data?.periodoFim);
      })
      .catch((error: AxiosError) => {
        api.post("/logs/frontEnd", {
          log: JSON.stringify(error.stack),
        });
      })
      .finally(() => {
        setLoading1(true);
      });
    //Tabela
    api
      .post<IAccordionNotificationHistory>(
        `/notificationsHistory/table/${eStation}/${pag}/${ordem}`,
        {
          dataInicial: dtini,
          dataFinal: dtfim,
        }
      )

      .then((response) => {
        setAccordionNotification(response.data);
      })
      .catch((error: AxiosError) => {
        api.post("/logs/frontEnd", {
          log: JSON.stringify(error.stack),
        });
      })
      .finally(() => setLoading2(true));
  };

  const dataInicial = dataInit.split("/");
  const dataFinal = dataFina.split("/");

  const dateInitial = `${
    dataInicial[2] + "/" + dataInicial[1] + "/" + dataInicial[0]
  } `;

  const dateFinale = `${
    dataFinal[2] + "/" + dataFinal[1] + "/" + dataFinal[0]
  } `;

  return loading1 && loading2 ? (
    <>
      <Container fluid>
        <Row className="mb-2 mb-xl-4">
          <Col xs="auto" className=" d-sm-block">
            <h6
              style={{
                padding: 0,
                margin: 0,
              }}
            >
              ANÁLISE DE NOTIFICAÇÕES ENVIADAS
            </h6>
            <p>{graphicNotification.estacao}</p>
          </Col>
          <Col xs="auto" className="ml-auto text-right ">
            <div
              style={{
                display: "flex",
                marginBottom: 0,
                paddingBottom: 0,
              }}
            >
              <Form ref={formRef} onSubmit={handleSubmit}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexWrap: "wrap",
                    marginTop: ".8rem",
                  }}
                >
                  <DatePicker
                    name="dtini"
                    label="De"
                    selectDate={new Date(dateInitial + " 00:00:00")}
                    required
                  />

                  <DatePicker
                    name="dtfim"
                    label="Até"
                    selectDate={new Date(dateFinale + " 00:00:00")}
                    required
                  />

                  <Button
                    type="submit"
                    style={{
                      width: "3rem",
                      height: "2rem",
                      backgroundColor: "#262F3D",
                      textAlign: "center",
                      justifyContent: "center",
                      justifyItems: "center",
                      alignContent: "center",
                      marginBottom: "15px",
                      padding: 0,
                    }}
                  >
                    <RefreshCw size={20} />
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
          {/* <hr style={{ background: "red" }} /> */}
        </Row>

        <br />

        <Row>
          {alertas.map((alerta, key) => (
            <Col md="3" xs="6" key={key}>
              <Card
                className="flex-fill"
                style={{
                  height: "10rem",
                }}
              >
                <CardBody className="py-4">
                  <Media>
                    <Bell className="feather-lg " color={color[key]} />
                    <Media body>
                      {alerta.quantidade > 9 ? (
                        <h5 className="mb-2"> {alerta.quantidade}</h5>
                      ) : (
                        <h3 className="mb-2"> 0{alerta.quantidade}</h3>
                      )}
                      <p className="mb-2">{alerta.periodo}</p>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
        <Row>
          <HistoryNotifications
            estacao={graphicNotification.estacao}
            timeStamp_Array={
              graphicNotification.graph_NotifyStatistics?.timeStamp_Array
            }
            whatsApp_Array={
              graphicNotification.graph_NotifyStatistics?.whatsApp_Array
            }
            sms_Array={graphicNotification.graph_NotifyStatistics?.sms_Array}
            app_Array={graphicNotification.graph_NotifyStatistics?.app_Array}
          />
        </Row>
        <Row className="mb-2 mb-xl-4">
          <Col xs="auto" className=" d-sm-block">
            <h6
              style={{
                padding: 0,
                margin: 0,
              }}
            >
              HISTÓRICO DE NOTIFICAÇÕES
            </h6>

            <p
              style={{
                padding: 0,

                marginTop: 10,
                marginBottom: 20,
              }}
            >
              <i>
                Período de {graphicNotification.periodoInicio} à{" "}
                {graphicNotification.periodoFim}
              </i>{" "}
            </p>
          </Col>
          <Col md="12" className="d-sm-none ml-auto text-left mt-n4">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "10px",
              }}
            >
              <p style={{ marginRight: "10px", marginTop: "20px" }}>
                Ordenar por:
              </p>

              <Input
                type="select"
                id="exampleCustomSelect"
                name="customSelect"
                className="mb-3"
                style={{
                  height: 32,
                  width: "6rem",
                  marginTop: "10px",
                  textAlign: "center",
                  padding: 0,
                }}
                value={ordem}
                onChange={handleSelecOrder}
              >
                <option value="asc">mais antigo</option>
                <option value="desc">mais novo</option>
              </Input>
            </div>
          </Col>
          <Col lg="2" className="mobile ml-auto text-left mt-n1">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "10px",
              }}
            >
              <p style={{ marginRight: "10px", marginTop: "20px" }}>
                Ordenar por:
              </p>

              <Input
                type="select"
                id="exampleCustomSelect"
                name="customSelect"
                className="mb-3"
                style={{ height: 32, width: "7rem", marginTop: "10px" }}
                value={ordem}
                onChange={handleSelecOrder}
              >
                <option value="asc">mais antigo</option>
                <option value="desc">mais novo</option>
              </Input>
            </div>
          </Col>

          {accordionNotification.historicoNotificacoes?.length > 0 ? (
            <Col lg="12" className="h-flex">
              {accordionNotification.historicoNotificacoes?.map(
                (accordion, key) => {
                  return (
                    <div key={"sad" + key.toString()}>
                      <Accordion defaultActiveKey={key.toString()}>
                        <Accordion.Item eventKey={key.toString()}>
                          <Accordion.Header>
                            <h6 className="paragrafoAccordion">
                              {accordion.date_Hour} - {accordion.notification}{" "}
                            </h6>
                          </Accordion.Header>
                          <Accordion.Body>
                            <p className="paragrafoAccordion">
                              <AlertCircle size={22} />{" "}
                              {accordion.justificativa ? (
                                <>
                                  {accordion.justificativa}

                                  {accordion.justificavel && (
                                    <ModalJustificativaInterno
                                      rash={accordion.id.toString()}
                                    />
                                  )}
                                </>
                              ) : (
                                <>
                                  <span>
                                    *Usuário ainda não inseriu justificativa
                                    para este alerta*
                                  </span>
                                  {accordion.justificavel && (
                                    <ModalJustificativaInterno
                                      rash={accordion.id.toString()}
                                    />
                                  )}
                                </>
                              )}
                              <br />
                              <AiOutlineWhatsApp size={22} />
                              <FontAwesomeIcon
                                className="align-middle mr-1"
                                icon={faCheck}
                                fixedWidth
                              />
                              {accordion?.whatsappEnviadoEm}
                              {accordion?.whatsappEnviadoEm && (
                                <FontAwesomeIcon
                                  className="align-middle mr-1"
                                  icon={faCheckDouble}
                                  fixedWidth
                                />
                              )}
                              {accordion?.whatsappRecebidoEm}
                              {accordion?.whatsappRecebidoEm && (
                                <FontAwesomeIcon
                                  className="align-middle mr-1"
                                  color={"#007bff"}
                                  icon={faCheckDouble}
                                  fixedWidth
                                />
                              )}
                              {accordion?.whatsappLidoEm}
                              {accordion?.smsEnviadoEm && <AiOutlineMessage />}
                              {accordion?.smsEnviadoEm}
                            </p>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      <br />
                    </div>
                  );
                }
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <Button
                  color="primary"
                  outline
                  /*  disabled={pag === 1 ? true : false} */
                  onClick={() => {
                    hanslePrevious();
                  }}
                >
                  {" < "}
                </Button>

                <Button color="primary" outline disabled>
                  {pag}
                </Button>

                <Button
                  color="primary"
                  outline
                  /*  disabled={pag === accordions.totalDePaginas ? true : false} */
                  onClick={() => {
                    handleNextPag();
                  }}
                >
                  {" > "}
                </Button>
              </div>
            </Col>
          ) : (
            <h5 style={{ textAlign: "center" }}>
              Sem notificações para o período
            </h5>
          )}
        </Row>
      </Container>
      <ScrollTop style={{ bottom: "80px" }} />
    </>
  ) : (
    <Loading width={10} height={10} color="primary" />
  );
};
