import React, { useEffect, useState } from "react";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { useForm } from "react-hook-form";
import api from "../../../services/api";
import { AxiosError } from "axios";
import { toastr } from "react-redux-toastr";

type FormData = {
  name: string;
  email: string;
  cell: string;
  status: boolean;
  roleUser: boolean;
  roleAdmin: boolean;
  roleMaster: boolean;
  notifica_Web: boolean;
  notifica_APP: boolean;
  notifica_SMS: boolean;
  notifica_WhatsApp: boolean;
  notifica_torpedoVoz: boolean;
};

export const UpdatedUserNotification = () => {
  const [app, setApp] = useState(false);
  const [web, setWeb] = useState(false);
  const [whatsapp, setWhatsapp] = useState(false);
  const [sms, setSms] = useState(false);
  const [voz, setVoz] = useState(false);

  const { register, handleSubmit, setValue } = useForm<FormData>({
    defaultValues: {
      status: false,
      notifica_APP: false,
      notifica_Web: false,
      notifica_SMS: false,
      notifica_torpedoVoz: false,
      notifica_WhatsApp: false,
    },
  });

  const notificaAPP = () => {
    setApp(!app);
  };

  const notificaWeb = () => {
    setWeb(!web);
  };

  const notificaWhatsapp = () => {
    setWhatsapp(!whatsapp);
  };

  const notificaSms = () => {
    if (voz) {
      setVoz(false);
      setSms(true);
    } else {
      setSms(!sms);
    }
  };

  const notificaVoz = () => {
    if (sms) {
      setVoz(true);
      setSms(false);
    } else {
      setVoz(!voz);
    }
  };

  useEffect(() => {
    setValue("notifica_APP", app);
  }, [app, setValue]);

  useEffect(() => {
    setValue("notifica_Web", web);
  }, [web, setValue]);

  useEffect(() => {
    setValue("notifica_WhatsApp", whatsapp);
  }, [whatsapp, setValue]);

  useEffect(() => {
    setValue("notifica_SMS", sms);
  }, [sms, setValue]);

  useEffect(() => {
    setValue("notifica_torpedoVoz", voz);
  }, [voz, setValue]);

  const onSubmit = ({
    name,
    email,
    cell,
    status,
    notifica_APP,
    notifica_Web,
    notifica_SMS,
    notifica_torpedoVoz,
    notifica_WhatsApp,
    roleAdmin,
    roleMaster,
    roleUser,
  }: FormData) => {
    let newRoles = [];

    if (roleAdmin) {
      newRoles.push(3);
    }

    if (roleUser) {
      newRoles.push(4);
    }
    if (roleMaster) {
      newRoles.push(5);
    }

    if (newRoles.length > 0) {
      api
        .post(`/users/admin`, {
          name,
          email,
          cell: cell.replace(/[^0-9]+/g, ""),
          roles: newRoles,
          status: status ? 1 : 0,
          notifica_APP: notifica_APP ? 1 : 0,
          notifica_Web: notifica_Web ? 1 : 0,
          notifica_SMS: notifica_SMS ? 1 : 0,
          notifica_torpedoVoz: notifica_torpedoVoz ? 1 : 0,
          notifica_WhatsApp: notifica_WhatsApp ? 1 : 0,
        })
        .then(() => {
          const toastrInstance = toastr.success;
          const options = {
            timeOut: 1000 * 10,
            showCloseButton: true,
            progressBar: true,
            position: "top-right",
          };
          toastrInstance("", "Usuário cadastrado com sucesso!.", options);
        })
        .catch((error: AxiosError) => {
          const toastrInstance = toastr.error;
          const options = {
            timeOut: 1000 * 10,
            showCloseButton: true,
            progressBar: true,
            position: "top-right",
          };
          toastrInstance(
            "",
            "Todos os campos devem ser preenchido corretamente!",
            options
          );
          api.post("/logs/frontEnd", {
            log: JSON.stringify(error.stack),
          });
        });
    } else {
      api
        .post(`/users/admin`, {
          name,
          email,
          cell: cell.replace(/[^0-9]+/g, ""),
          status: status ? 1 : 0,
          notifica_APP: notifica_APP ? 1 : 0,
          notifica_Web: notifica_Web ? 1 : 0,
          notifica_SMS: notifica_SMS ? 1 : 0,
          notifica_torpedoVoz: notifica_torpedoVoz ? 1 : 0,
          notifica_WhatsApp: notifica_WhatsApp ? 1 : 0,
        })
        .then(() => {
          const toastrInstance = toastr.success;
          const options = {
            timeOut: 1000 * 10,
            showCloseButton: true,
            progressBar: true,
            position: "top-right",
          };
          toastrInstance("", "Usuário cadastrado com sucesso!.", options);
        })
        .catch((error: AxiosError) => {
          console.log("Erro ao cadastrar usuario");
          const toastrInstance = toastr.error;
          const options = {
            timeOut: 1000 * 10,
            showCloseButton: true,
            progressBar: true,
            position: "top-right",
          };
          toastrInstance(
            "",
            "Todos os campos devem ser preenchido corretamente!",
            options
          );
          api.post("/logs/frontEnd", {
            log: JSON.stringify(error.stack),
          });
        });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "1rem" }}>
        <p className="font-bold block mb-2">
          Notificações
        </p>
        <FormGroup switch>
          <Input
            type="switch"
            id="notifica_APP"
            label=""
            name="notifica_APP"
            checked={app}
            onChange={() => notificaAPP()}
            inputRef={register}
            defaultChecked
          />
          <Label>App</Label>
        </FormGroup>

        <FormGroup switch>
          <Input
            type="switch"
            id="notifica_Web"
            label=""
            name="notifica_Web"
            checked={web}
            onChange={() => notificaWeb()}
            inputRef={register}
            defaultChecked
          />
          <Label>Web</Label>
        </FormGroup>

        <FormGroup switch>
          <Input
            type="switch"
            id="notifica_WhatsApp"
            label=""
            name="notifica_WhatsApp"
            checked={whatsapp}
            onChange={() => notificaWhatsapp()}
            inputRef={register}
          />
          <Label>WhatsApp</Label>
        </FormGroup>

        <FormGroup switch>
          <Input
            type="switch"
            id="notifica_SMS"
            label=""
            name="notifica_SMS"
            checked={sms}
            onChange={() => notificaSms()}
            inputRef={register}
          />
          <Label>SMS</Label>
        </FormGroup>

        <FormGroup switch>
          <Input
            type="switch"
            id="notifica_torpedoVoz"
            label=""
            name="notifica_torpedoVoz"
            checked={voz}
            onChange={() => notificaVoz()}
            inputRef={register}
          />

          <Label>Mensagem de voz</Label>
        </FormGroup>

        <div className="d-flex mt-4 justify-content-end">
          <Button
            type="submit"
            color="primary"
            size="md"
            className="mr-1 mb-1"
            outline
          >
            Salvar
          </Button>
          <Button color="secondary" size="md" className="mr-1 mb-1" outline>
            Cancelar
          </Button>
        </div>
      </form>
    </>
  );
};
