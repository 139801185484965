import { useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Button, CardBody, Card, Input } from "reactstrap";

import { SubmitHandler, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { toastr } from "react-redux-toastr";
import api from "../../services/api";

import supplyLogo from "../../assets/supply/logo_mini_supply.png";

type Inputs = {
  tel: string;
};
const IsUser = () => {
  const history = useHistory();
  const [errorLogin, setErrorLogin] = useState(false);

  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  //http://localhost:3000/?plataforma=IOS&deviceid=E8E49E30-2CA4-46C6-921E-5A58849B062D&pushtoken=45f0865d93e2d440db2985979089eba5b418a36259df47675b6bb5c8c9961c96&versao=2.0.0

    if (
      query.get("deviceid") !== null &&
      query.get("plataforma") !== null &&
      query.get("versao") !== null
    ) {
      localStorage.setItem("@supply:device_id", String(query.get("deviceid")));
      localStorage.setItem(
        "@supply:plataform",
        String(query.get("plataforma"))
      );
      localStorage.setItem("@supply:version", String(query.get("versao")));
      localStorage.setItem("@supply:fcm", String(query.get("fcm")));
      localStorage.setItem("@supply:pushtoken", String(query.get("pushtoken")));
      localStorage.setItem("@supply:urlPath", window.location.href);
    }

   


  const { register, handleSubmit } = useForm<Inputs>({});
  const onSubmit: SubmitHandler<Inputs> = ({ tel }: Inputs) => {
    api
      .post("/users/cell", {
        cell: tel.replace(/[^0-9]+/g, ""),
      })
      .then((response) => {
        localStorage.setItem("@supply:cell", response.data.cell);
        localStorage.setItem("@supply:name", response.data.name);
        setErrorLogin(false);
        setTimeout(() => {
          history.push("/acess");
        }, 200);
      })

      .catch((err) => {
        setErrorLogin(true);
        const toastrInstance = toastr.warning;
        const options = {
          timeOut: 1000 * 10,
          showCloseButton: true,
          progressBar: true,
          position: "top-right",
        };
        toastrInstance(
          "",
          "Ops! Não conseguimos encontrar seu cadastro.Caso precise de ajuda  fale com nosso suporte técnico.",
          options
        );
      });
  };

  return (
    <>
      <div
        style={{
          margin: 0,
          display: "grid",
          flex: 1,
          height: "100%",
          justifyContent: "center",
          alignContent: "center",
          justifySelf: "center",
        }}
      >
        <Card style={{ maxWidth: "600px", maxHeight: "600px", marginTop: 20 }}>
          <CardBody>
            <div className="m-sm-4">
              <div className="text-center">
                <img
                  src={supplyLogo}
                  alt="App supply"
                  className="img-fluid "
                  width="132"
                  height="132"
                />
              </div>
              <br />
              <div className="text-center">
                <h5>Seja bem vindo!</h5>
              </div>
              <div className="text-center mt-4">
                <p className="lead">Insira o seu celular para começar!</p>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <InputMask {...register("tel")} mask="(99) 99999-9999">
                    {() => (
                      <Input
                        name="tel"
                        type="tel"
                        placeholder="(00) 00000-0000"
                        style={{
                          width: "10rem",
                        }}
                      />
                    )}
                  </InputMask>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: 20,
                    justifyContent: "center",
                  }}
                >
                  <Button
                    name="enviar"
                    color="primary"
                    type="submit"
                    className="mr-1 mb-1"
                  >
                    Acessar
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: 20,
                    justifyContent: "center",
                  }}
                >
                  {errorLogin && (
                    <a
                      className="text-gray-300"
                      rel="noreferrer"
                      href="https://wa.me/5511932227105?text=Olá, não consigo realizar o acesso. Parece que meu número não está cadastrado"
                      target="_blank"
                    >
                      <span> Ajuda</span>
                    </a>
                  )}
                </div>
              </form>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default IsUser;
