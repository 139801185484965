import Routes from "./Routes";


import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import store from "./redux/store/index";
import { AuthProvider } from "./context/AuthContext";
import { MenuProvider } from "./context/MenuContext";



import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Provider store={store}>
        <AuthProvider>
          <MenuProvider>
            <Routes />
          </MenuProvider>
          <ReduxToastr
            timeOut={10000}
            newestOnTop={true}
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            closeOnToastrClick
          />
        </AuthProvider>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
