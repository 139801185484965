import { Link, useParams } from "react-router-dom";

import { Card, CardBody, CardHeader } from "reactstrap";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

interface HistoricoEnergeticoData {
  VerMais: string;
  time_arr: string[];
  energizado_arr: number[];
  desenergizado_arr: number[];
  atualizadoEm?: string;
}

export default function Column({
  VerMais,
  energizado_arr,
  desenergizado_arr,
  time_arr,
  atualizadoEm,
}: HistoricoEnergeticoData) {
  const { eStation }: { eStation: string } = useParams();
  const data = [
    {
      name: "Energizado",
      y: energizado_arr || [0, 0, 0],
    },
    {
      name: "Desenergizado",
      y: desenergizado_arr || [0, 0, 0],
    },
  ];

  // Configuração do gráfico de barras
  const options = {
    chart: {
      type: "column", // Definindo o tipo de gráfico como 'bar'
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: time_arr, // Usamos os nomes como categorias no eixo X
    },
    yAxis: {
      title: {
        text: "Tempo (em horas)",
      },
      min: 0, // Valor mínimo do eixo Y (0%)
      max: 24, // Valor máximo do eixo Y (100%)
      tickInterval: 4, // Intervalo dos ticks do eixo Y (4% de intervalo, representando 0, 4, 8, 12, ..., 24)

    },
    credits: {
      enabled: true,
      text: "appsupply",
      href: "https://painel.appsupply.ml/",
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    series: [
      {
        name: "Enegizado",
        data: data[0].y, // Usamos os valores (y) como dados para o gráfico de barras
      },

      {
        name: "Desernegizado",
        data: data[1].y, // Usamos os valores (y) como dados para o gráfico de barras
      },
    ],
    legend: {
      enabled: false, // Desabilita a exibição da legenda
    },
  };
  return (
    <Card style={{ width: "100%" }}>
      <CardHeader>
        <h5 className=" text-muted">Histórico de Fornecimento Energético</h5>
        <p className=" text-muted">(nos últimos dias)</p>
      </CardHeader>
      <CardBody>
        <HighchartsReact highcharts={Highcharts} options={options} />

        <Link to={`/dataHistory/${eStation}/${VerMais}`}>
          <p
            style={{
              width: "100%",
              textAlign: "right",
              fontSize: "12px",
            }}
          >
            [Ver mais]
          </p>
        </Link>
      </CardBody>
    </Card>
  );
}
